import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  ListItemText,
  FormHelperText,
  Tooltip, // Import FormHelperText
  Tab,
  Tabs,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDialog from '../ErrorDialog';
import { MuiColorInput } from 'mui-color-input'; // Import MuiColorInput
import useWindowSize from '../../hooks/useWindowSize';
import CustomDataGrid from '../CustomDataGrid';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import SearchBar from '../SearchBar';
import roles from '../../hooks/Roles';
import MobileViewComponent from '../MobileView';
import { GetTextByRegion } from '../../hooks/Translation';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const UserTable = ({ users, setUsers, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, seletedRegion, tabValue, setTabValue
}) => {
  const [validationErrors, setValidationErrors] = useState({
    username: '',
    role: '',
    region: '',
    email: '',
  });
  const [editUserId, setEditUserId] = useState(null);
  const [editUsername, setEditUsername] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editRole, setEditRole] = useState('');
  const [editRegion, setEditRegion] = useState([]);
  const [editColor, setEditColor] = useState('#ffffff'); // Initialize with a default color
  const [editEmail, setEditEmail] = useState('');
  const [editUtility, setEditUtility] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [error, setError] = useState(null);
  const { windowSize, smallWidth } = useWindowSize();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    let filtered = users;

    if (tabValue === 1) {
      // Only users with isUtility set to true for tab1
      filtered = users.filter(user => user.isUtility);
    } else {
      // For tab0, exclude users with isUtility set to true
      filtered = users.filter(user =>
        !user.isUtility &&
        (
          (user.username && user.username.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (user.role && user.role.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (user.email && user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (user.region && Array.isArray(user.region) && user.region.some(region => region.toLowerCase().includes(searchQuery.toLowerCase())))
        )
      );
    }

    // Sort alphabetically by username
    filtered.sort((a, b) => {
      if (!a.username) return 1;
      if (!b.username) return -1;
      return a.username.localeCompare(b.username);
    });

    setFilteredUsers(filtered);
  }, [searchQuery, users, tabValue]);




  const handleEdit = async (params) => {
    const userId = params.row._id;
    const userToEdit = users.find(user => user._id === userId);
    if (userToEdit) {
      setEditUserId(userToEdit._id);
      setEditUsername(userToEdit.username);
      setEditPassword(userToEdit.password);
      setEditRole(userToEdit.role);
      setEditColor(userToEdit.color || '#ffffff'); // Set color if it exists, otherwise default to #ffffff
      setEditRegion(userToEdit.region);
      setEditEmail(userToEdit.email);
      setEditUtility(userToEdit.isUtility)
      setOpenEditDialog(true);
    }
  };

  const handleEditSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const updatedUser = {
        _id: editUserId,
        username: editUsername,
        password: editPassword, // Although it's not recommended to store raw passwords
        role: editRole,
        region: editRegion,
        color: editColor,
        email: editEmail,
        isUtility: editUtility
      };

      await axios.put(`${backendString}/users/${editUserId}`, updatedUser, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      setOpenEditDialog(false);

      // Update the state with the new user data
      setUsers(prevData =>
        prevData.map(item =>
          item._id === editUserId ? updatedUser : item
        )
      );
    } catch (error) {
      console.error('Error editing user:', error);
      setError(error.response?.data?.error);
    }
  };


  const handleDelete = async (params) => {
    const userId = params.row._id;
    setDeleteUserId(userId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async (id) => {
    try {
      await axios.delete(backendString + `/users/${id}`,
        {
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }
        });
      setOpenDeleteDialog(false);
      setUsers(prevData =>
        prevData.filter(item => item._id !== id)
      );
    } catch (error) {
      console.error('Error deleting user:', error);
      setError(error.response?.data?.error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditUserId(null);
    setEditUsername('');
    setEditPassword('');
    setEditRole('');
    setEditEmail('');
    setEditUtility(false);
    setEditRegion(['USA']);
    setEditColor('#d3d3d3'); // Reset color to default
    setValidationErrors({ username: '', role: '', region: '' }); // Reset validation errors
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteUserId(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleColorChange = (color) => {
    setEditColor(color); // Update the color state directly
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const validateForm = () => {
    const errors = { username: '', role: '', region: '' };
    let isValid = true;

    if (!editUsername.trim()) {
      errors.username = GetTextByRegion('User name is required', seletedRegion);
      isValid = false;
    }

    if (!editRole) {
      errors.role = GetTextByRegion('Role is required', seletedRegion);
      isValid = false;
    }

    if (!editEmail) {
      errors.role = GetTextByRegion('Email is required', seletedRegion);
      isValid = false;
    }

    if (editRegion.length === 0) {
      errors.region = GetTextByRegion('At least one region must be selected', seletedRegion);
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const columns = [
    { field: 'username', headerName: GetTextByRegion('Username', seletedRegion), flex: 1 },
    { field: 'email', headerName: GetTextByRegion('Email', seletedRegion), flex: 1 },
    { field: 'role', headerName: GetTextByRegion('Role', seletedRegion), flex: 1 },
    { field: 'region', headerName: GetTextByRegion('Region', seletedRegion), flex: 1 },
    {
      field: 'color',
      headerName: GetTextByRegion('Color', seletedRegion),
      flex: 1,
      renderCell: (params) => (
        <Box
          width={20}
          height={20}
          bgcolor={params.value || '#d3d3d3'}
          borderRadius="50%"
          marginTop={3}
        />
      )
    },
    {
      field: 'actions',
      headerName: GetTextByRegion('Actions', seletedRegion),
      width: 150,
      renderCell: (params) => (
        <Box>
          <Tooltip title={GetTextByRegion("Edit", seletedRegion)} arrow>
            <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={GetTextByRegion("Delete", seletedRegion)} arrow>
            <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  ];

  const mobileColumns = [
    { label: "Username", name: GetTextByRegion("username", seletedRegion), type: "text", value: "username", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Email", name: GetTextByRegion("email", seletedRegion), type: "text", value: "email", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Role", name: GetTextByRegion("role", seletedRegion), type: "text", value: "role", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Region", name: GetTextByRegion("region", seletedRegion), type: "text", value: "region", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Color", name: GetTextByRegion("color", seletedRegion), type: "text", value: "color", required: true, InputLabelProps: {}, position: 'left' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.75, width: windowSize.width * 1 }}>
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={GetTextByRegion("Search Users...", seletedRegion)} />

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile>
        <Tab label={GetTextByRegion("Users", seletedRegion)} />
        <Tab label={GetTextByRegion("Utility Users", seletedRegion)} />
      </Tabs>

      {windowSize.width < smallWidth ? (
        <MobileViewComponent items={filteredUsers} columnData={mobileColumns} handleEditClick={handleEdit} handleDelete={handleDelete} />
      ) : (
        <CustomDataGrid rows={filteredUsers} columns={columns} />
      )}

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{GetTextByRegion("Edit User", seletedRegion)}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300}>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label={GetTextByRegion("Username", seletedRegion)}
              type="text"
              fullWidth
              value={editUsername}
              onChange={(e) => setEditUsername(e.target.value)}
              error={!!validationErrors.username && !editUsername}
              helperText={editUsername ? '' : validationErrors.username}
            />
            {tabValue === 0 && (
              <TextField
                margin="dense"
                id="password"
                label={GetTextByRegion("Password", seletedRegion)}
                type="password"
                fullWidth
                disabled
                value={editPassword}
                onChange={(e) => setEditPassword(e.target.value)}
              />
            )}

            <TextField
              margin="dense"
              id="email"
              label={GetTextByRegion("Email", seletedRegion)}
              fullWidth
              required
              multiline
              value={editEmail}
              onChange={(e) => setEditEmail(e.target.value)}
              error={!!validationErrors.email && !editEmail}
              helperText={editEmail ? '' : validationErrors.email}
            />
            {tabValue === 0 && (
              <FormControl variant="outlined" fullWidth error={!!validationErrors.role && !editRole}>
                <InputLabel>{GetTextByRegion("Role", seletedRegion)}</InputLabel>
                <Select
                  label="Role"
                  name="role"
                  value={editRole}
                  onChange={(e) => setEditRole(e.target.value)}
                  renderValue={(selected) => (selected ? selected : <em>None</em>)}
                >
                  {Object.keys(roles).map((role) => (
                    <MenuItem key={role} value={role}>{role}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{editRole ? '' : validationErrors.role}</FormHelperText>
              </FormControl>
            )}
            <FormControl variant="outlined" fullWidth error={!!validationErrors.region && editRegion.length === 0}>
              <InputLabel>{GetTextByRegion("Regions", seletedRegion)}</InputLabel>
              <Select
                label={GetTextByRegion("Regions", seletedRegion)}
                name="region"
                value={editRegion}
                onChange={(e) => setEditRegion(e.target.value)}
                multiple
                renderValue={(selected) => selected.join(', ')}
                inputProps={{ id: 'region' }}
              >
                <MenuItem value="USA">
                  <ListItemText primary="USA" />
                </MenuItem>
                <MenuItem value="MEXICO">
                  <ListItemText primary="MEXICO" />
                </MenuItem>
                <MenuItem value="EU">
                  <ListItemText primary="EU" />
                </MenuItem>
                <MenuItem value="CHINA">
                  <ListItemText primary="CHINA" />
                </MenuItem>
                <MenuItem value="TAIWAN">
                  <ListItemText primary="TAIWAN" />
                </MenuItem>
              </Select>
              <FormHelperText>{editRegion.length !== 0 ? '' : validationErrors.region}</FormHelperText>
            </FormControl>
            
            {tabValue === 0 && (
              <MuiColorInput
                label={GetTextByRegion("Color", seletedRegion)}
                name="color"
                value={editColor}
                onChange={handleColorChange}
                variant="outlined"
              />
            )}

            {/* Checkbox for isUtility */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={editUtility}
                  onChange={(e) => setEditUtility(e.target.checked)}
                  color="primary"
                />
              }
              label={GetTextByRegion("Utility User", seletedRegion)}
            />

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>{GetTextByRegion("Cancel", seletedRegion)}</Button>
          <Button onClick={handleEditSubmit} variant="contained" color="primary">{GetTextByRegion("Save", seletedRegion)}</Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={() => handleDeleteConfirm(deleteUserId)}
      />

      {/* Render the error dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default UserTable;
