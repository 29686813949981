import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel, ListItemText } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import ErrorDialog from '../ErrorDialog';
import roles from '../../hooks/Roles';
import { GetTextByRegion } from '../../hooks/Translation';
import regions from '../../hooks/Regions';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const UsersForm = ({ onAdd, onClose, selectedRegion, tabValue }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    role: 'SERVICE',
    color: '#d3d3d3',
    region: ['USA'],
    isUtility: tabValue === 0 ? false : true,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [error, setError] = useState(null);

  // UseEffect to update `isUtility` whenever `tabValue` changes
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      isUtility: tabValue === 0 ? false : true,
    }));
  }, [tabValue]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegionChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      region: value, // Since `value` is already handled as an array by Select component
    });
  };

  const handleColorChange = (color) => {
    setFormData({
      ...formData,
      color // Directly update the color
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.username) errors.username = GetTextByRegion('Username is required', selectedRegion);
    if (!formData.password) errors.password = GetTextByRegion('Password is required', selectedRegion);
    if (!formData.email) errors.email = GetTextByRegion('Email is required', selectedRegion);
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = GetTextByRegion('Passwords do not match', selectedRegion);
    }
    // if(!formData.confirmPassword) errors.confirmPassword = 'Confirm Password is required';
    if (!formData.region || formData.region.length === 0) {
      errors.region = GetTextByRegion('At least one region must be selected', selectedRegion);
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const generatePassword = (length = 8) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return password;
  };

  const addUser = async () => {
    if(tabValue === 1){
      const autoGeneratedPassword = generatePassword();
      setFormData((prevFormData) => ({
        ...prevFormData,
        password: autoGeneratedPassword,
        confirmPassword: autoGeneratedPassword,
      }));
    }else {
      if (!validateForm()) return; // Don't proceed if validation fails
    }
    try {
      const response = await axios.post(`${backendString}/users/register`, formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      onAdd(response.data);
      onClose();
    } catch (error) {
      console.error(error);
      setError(error.response?.data?.error || 'Failed to add user');
    }
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  return (
    <div>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>{GetTextByRegion("Add User", selectedRegion)}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} width={300} mt={1}>
            <TextField
              label={GetTextByRegion("Username", selectedRegion)}
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
              variant="outlined"
              error={!!validationErrors.username && !formData.username}
              helperText={formData.username ? '' : validationErrors.username}
            />
            {tabValue === 0 && (
              <>
                <TextField
                  label={GetTextByRegion("Password", selectedRegion)}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  variant="outlined"
                  type="password"
                  error={!!validationErrors.password && !formData.password}
                  helperText={formData.password ? '' : validationErrors.password}
                />
                <TextField
                  label={GetTextByRegion("Confirm Password", selectedRegion)}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                  variant="outlined"
                  type="password"
                  error={!!validationErrors.confirmPassword}
                  helperText={validationErrors.confirmPassword}
                />
              </>
            )}
            <TextField
              label={GetTextByRegion("Email", selectedRegion)}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              multiline
              variant="outlined"
              error={!!validationErrors.email && !formData.email}
              helperText={formData.email ? '' : validationErrors.email}
            />
            {tabValue === 0 && (
              <FormControl variant="outlined" fullWidth required error={!!validationErrors.role}>
                <InputLabel>{GetTextByRegion("Role", selectedRegion)}</InputLabel>
                <Select
                  label="Role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  {Object.keys(roles).map((role) => (
                    <MenuItem key={role} value={role}>{role}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl variant="outlined" required error={!!validationErrors.region}>
              <InputLabel shrink htmlFor="model">
                {GetTextByRegion("Regions", selectedRegion)}
              </InputLabel>
              <Select
                label="Regions"
                name="region"
                value={formData.region}
                onChange={handleRegionChange}
                multiple
                inputProps={{ id: 'region' }}
                renderValue={(selected) => selected.join(', ')}
              >
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    <ListItemText primary={region} />
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.region && <p style={{ color: 'red' }}>{validationErrors.region}</p>}
            </FormControl>
            {tabValue === 0 && (
              <MuiColorInput
                label={GetTextByRegion("Color", selectedRegion)}
                name="color"
                value={formData.color}
                onChange={handleColorChange}
                variant="outlined"
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            {GetTextByRegion("Cancel", selectedRegion)}
          </Button>
          <Button onClick={addUser} variant="contained" color="primary">
            {GetTextByRegion("Add User", selectedRegion)}
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default UsersForm;
