import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import ErrorDialog from '../ErrorDialog';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import axios from 'axios';
import SearchBar from '../SearchBar';
import CustomDataGrid from '../CustomDataGrid';
import MobileViewComponent from '../MobileView';
import useWindowSize from '../../hooks/useWindowSize';
import Papa from 'papaparse';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const HistoryPopup = ({ open, onClose }) => {
  const [error, setError] = useState(null);
  const [itemHistories, setItemHistories] = useState([]);
  const [filteredItemHistories, setFilteredItemHistories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { selectedRegion } = useUser();
  const { windowSize, smallWidth } = useWindowSize();

  useEffect(() => {
    const fetchItemHistories = async () => {
      try {
        const response = await axios.get(`${backendString}/itemhistories`, {
          params: { selectedRegion }
        });
        const sortedHistories = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort in descending order
        setItemHistories(sortedHistories);
      } catch (err) {
        setError(err.message);
      }
    };

    if (open && selectedRegion) {
      fetchItemHistories(); // Fetch histories when dialog is opened and region is available
    }
  }, [open, selectedRegion]); // Trigger effect when `open` or `selectedRegion` changes

  useEffect(() => {
    let itemsToFilter = itemHistories.filter(item =>
      (item.partnumber && item.partnumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.action && item.action.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredItemHistories(itemsToFilter);
  }, [searchQuery, itemHistories]);

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const exportToCSV = () => {
    // Map the items to exclude the _id field and ensure all fields are present
    const dataToExport = filteredItemHistories.map(item => {
      const { _id, ...rest } = item;

      // Ensure all fields are included with default values if they are empty or missing
      return {
        partnumber: rest.partnumber || '',
        action: rest.action || '',
        quantity: rest.quantity || '',
        ship_to_from: rest.shipto || '',
        ponumber: rest.ponumber || '',
        purpose: rest.purpose || '',
        username: rest.username || '',
        timestamp: rest.timestamp || ''
      };
    });

    // Convert data to CSV format
    const csvData = Papa.unparse(dataToExport);

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, ''); // Remove slashes to get mmddyyyy format

    // Create a link element for downloading the CSV file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `history-${formattedDate}.csv`;

    // Programmatically click the link to trigger the download
    link.click();
  };

  const columns = [
    {
      field: 'partnumber', flex: 4, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Part Number', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'action', flex: 3, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Action', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'quantity', flex: 2, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Δ Quantity', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'shipto', flex: 3, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Shipped To/From', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'ponumber', flex: 3, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('PO Number', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'purpose', flex: 3, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Purpose', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'username', flex: 2, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Username', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'timestamp', flex: 3, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Time', selectedRegion)}<br /></Typography>),
    },
  ];

  const mobileColumns = [
    { label: "Part Number", name: "partnumber", type: "text", value: "partnumber", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Action", name: "action", type: "text", value: "action", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Quantity", name: "quantity", type: "number", value: "quantity", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Shipped To/From", name: "shipto", type: "text", value: "shipto", required: false, InputLabelProps: {}, position: 'left' },
    { label: "PO Number", name: "ponumber", type: "text", value: "ponumber", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Purpose", name: "purpose", type: "text", value: "purpose", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Username", name: "username", type: "text", value: "username", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Time", name: "timestamp", type: "text", value: "timestamp", required: false, InputLabelProps: {}, position: 'left' },
  ];

  return (

    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{GetTextByRegion("Inventory History", selectedRegion)}</DialogTitle>
      <DialogContent>
        <div style={{
          display: 'flex',
          flexDirection: 'row', // Stack buttons in column for mobile
          position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
          left: 'auto', // Align left on larger screens
        }}>
          <Button onClick={exportToCSV}>{GetTextByRegion("Export to CSV", selectedRegion)}</Button>
        </div>
        <Box display="flex" flexDirection="column" gap={1}>

          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder={GetTextByRegion("Search History...", selectedRegion)}
          />
          <Box height={windowSize.height * 0.5} display="flex" flexDirection="column">
            {windowSize.width < smallWidth ? (
              <MobileViewComponent items={filteredItemHistories} columnData={mobileColumns} selectedRegion={selectedRegion} />
            ) : (
              <CustomDataGrid rows={filteredItemHistories} columns={columns} />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{GetTextByRegion("Close", selectedRegion)}</Button>
      </DialogActions>
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </Dialog>

  );
};

export default HistoryPopup;
