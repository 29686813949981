import React, { useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import { Button} from '@mui/material';
import ChinaProductionTable from '../components/ChinaProduction/ChinaProductionTable';
import ChinaProductionForm from '../components/ChinaProduction/ChinaProductionForm';
import roles from '../hooks/Roles';
import {GetTextByRegion} from '../hooks/Translation';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import ErrorDialog from '../components/ErrorDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaProduction = (region) => {
  const [isMachinePopupOpen, setIsMachinePopupOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [machines, setMachines] = useState([]);
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  const [error, setError] = useState(null);
  const validateSelectedRegion = ["CHINA", "USA", "EU"];
  const {selectedRegion, user:{userRights}, refreshUser} = useUser();
  useValidateRegions(selectedRegion,validateSelectedRegion);

  const fetchMachines = useCallback(async () => {
    try {
      const response = await axios.get(`${backendString}/chinaproduction?region=${region.region}`);
      const filteredMachines = response.data.filter(machine => machine.regionTo === region.region);
      setMachines(filteredMachines);
    } catch (error) {
      console.error('Error fetching machines:', error);
      setError(error.response?.data?.error);
    }
  }, [setMachines, region]);  

  useEffect(() => {
    fetchMachines();
  }, [fetchMachines]);

  const handleOpenPopup = () => {
    setIsMachinePopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsMachinePopupOpen(false);
  };

  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleAddMachine = (newMachine) => {
    setMachines([...machines, newMachine]);
  };

  useEffect(() => {
    refreshUser();
  }, [refreshUser,isMachinePopupOpen,openEditDialog,openDeleteDialog]);
  
  return (
    <div>
      {(userRights >= roles['MANAGER'] || userRights === roles['SALESASSISTANT']) && selectedRegion === "CHINA" && <Button variant="contained" color="primary" onClick={handleOpenPopup}>
        {GetTextByRegion("Add New Machine",selectedRegion)}
      </Button> }
      {isMachinePopupOpen && userRights >= roles['SERVICE'] && (
        <ChinaProductionForm open={isMachinePopupOpen} onSubmit={handleAddMachine} onClose={handleClosePopup} mode="add" region={region} fetchMachines={fetchMachines} viewOnly={false} tabValue={tabValue}/>
      )}
      <ChinaProductionTable 
        machines={machines} 
        setMachines={setMachines} 
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        region={region}
        fetchMachines={fetchMachines}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />

      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
  };
  
  export default ChinaProduction;