import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Button, IconButton, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import Papa from 'papaparse';
import io from 'socket.io-client';
import ErrorDialog from '../ErrorDialog';
import EditItemForm from './EditItemForm';
import ImportCSVButton from './ImportCVSButton';
import SearchBar from '../SearchBar';
import CustomDataGrid from '../CustomDataGrid';
import MobileViewComponent from '../MobileView';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import placeholderImage from "../../images/placeholder-image.jpg";
import useWindowSize from '../../hooks/useWindowSize';
import roles from '../../hooks/Roles';
import { GetTextByRegion, GetCurrencySymbol } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import ImageDialog from './ImageDialog';

const backendString = process.env.REACT_APP_BACKEND_STRING;
const socket = io(backendString);

const InventoryTable = ({ items, setItems, openEditDialog, setOpenEditDialog, openDeleteDialog, setOpenDeleteDialog, filterByMinQuantity, setFilterByMinQuantity }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState(null); // State for error messages
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const { windowSize, smallWidth } = useWindowSize();
  const { selectedRegion, user: { userRights, username } } = useUser();
  const [openImageDialog, setOpenImageDialog] = useState(false); // State to control the dialog
  const [selectedImage, setSelectedImage] = useState(''); // State for the selected image
  const [columnVisibilityConfig, setColumnVisibilityModel] = useState();
  

  useEffect(() => {
    
    const Load = async () => {
      try {
        const response = await axios.get(`${backendString}/loadTableColumns?tableName=inventory`,{
          headers: {
            'Authorization': sessionStorage.getItem('token')
          }}
        );  
        let columns = typeof response.data === 'string' ? JSON.parse(response.data) : {};
        setColumnVisibilityModel(columns);
      } catch (error) {
        setError(error.response); // Set error state
      }
    }
    Load();
  },[]);

  let connectionString = backendString + '/items';

  const fetchItems = useCallback(async () => {

    try {
      const response = await axios.get(`${connectionString}?selectedRegion=${selectedRegion}`);
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching items:', error);
      setError(error.response?.data?.error || 'Error fetching items'); // Set error state
    }
  }, [setItems, connectionString, selectedRegion]);

  const handleWebSocketMessage = useCallback((message) => {
    // Handle the message (this function's logic will depend on your needs)
    console.log('WebSocket message received:', message);
    fetchItems(); // Fetch items again after receiving a message
  }, [fetchItems]);

  useEffect(() => {
    fetchItems();
    socket.on('Item', handleWebSocketMessage);
    return () => {
      socket.off('Item', handleWebSocketMessage);
    };
  }, [fetchItems, handleWebSocketMessage]);

  useEffect(() => {
    let itemsToFilter = items.filter(item =>
      (item.partnumber && item.partnumber.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.location && item.location.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.model && item.model.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    if (filterByMinQuantity) {
      itemsToFilter = itemsToFilter.filter(item => item.quantity <= item.minQuantity);
    }
    setFilteredItems(itemsToFilter);
  }, [searchQuery, items, filterByMinQuantity]);

  const handleEdit = (params) => {
    setSelectedItem(params.row);
    setOpenEditDialog(true);
  };

  const handleDelete = (params) => {
    setSelectedItem(params.row);
    setOpenDeleteDialog(true);
  };

  const handleEditSubmit = async () => {

    try {
      await axios.put(`${connectionString}/${selectedItem._id}?selectedRegion=${selectedRegion}`, selectedItem, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': sessionStorage.getItem('token')
        }
      });
      const historyEntry = {
        partnumber: selectedItem.partnumber,
        action: GetTextByRegion('EDIT ITEM', selectedRegion),
        username: username,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit', // Ensures month is two digits (e.g., "01" for January)
          day: '2-digit',   // Ensures day is two digits
          hour: '2-digit',  // 2-digit hour
          minute: '2-digit', // 2-digit minute
          hour12: false,    // Use 24-hour time
        }).replace(',', '') // Remove the comma between the date and time
      };

      // Add to history Log
      await axios.post(backendString + `/itemhistories?selectedRegion=${selectedRegion}`, historyEntry, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      socket.emit('Item', "edit item");
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error editing item:', error);
      setError(error.response?.data?.error); // Set error state
    }
  };

  const handleDeleteConfirm = async () => {
    try {

      await axios.delete(`${connectionString}/${selectedItem._id}?selectedRegion=${selectedRegion}`, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });

      const historyEntry = {
        partnumber: selectedItem.partnumber,
        action: GetTextByRegion('DELETE ITEM', selectedRegion),
        username: username,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit', // Ensures month is two digits (e.g., "01" for January)
          day: '2-digit',   // Ensures day is two digits
          hour: '2-digit',  // 2-digit hour
          minute: '2-digit', // 2-digit minute
          hour12: false,    // Use 24-hour time
        }).replace(',', '') // Remove the comma between the date and time
      };

      // Add to history Log
      await axios.post(backendString + `/itemhistories?selectedRegion=${selectedRegion}`, historyEntry, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      socket.emit('Item', "delete item");
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting item:', error);
      setError(error.response?.data?.error); // Set error state
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedItem(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedItem(null);
  };

  const handleCloseErrorDialog = () => {
    setError(null); // Reset error state
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    // Check if the field is "model" and handle accordingly
    if (name === "model") {
      // For "model", value will be an array since it's a Select with multiple
      const newValue = value.join(","); // Convert the array to a comma-separated string
      setSelectedItem(prevItem => ({
        ...prevItem,
        [name]: newValue
      }));
    } else {
      // For other fields like "price", handle as usual
      const newValue = name === "price" ? value.replace(GetCurrencySymbol(selectedRegion), '') : value;
      setSelectedItem(prevItem => ({
        ...prevItem,
        [name]: files ? files[0] : newValue
      }));
    }
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Inventory");

    // Define headers dynamically with "Image" column first
    const columns = [
      { header: "Image", key: "image" }, // Move Image column to the start
      ...Object.keys(filteredItems[0])
        .filter(key => key !== "_id" && key !== "image") // Exclude '_id' and 'image' fields
        .map(key => ({
          header: key === "name" ? "description" : key === "model" ? "equipmenttype" : key, // Change 'name' to 'description'
          key,
        })),
    ];
    worksheet.columns = columns;

    // Set default column width for images and other data
    worksheet.getColumn(1).width = 30; // Adjust width for the "Image" column
    worksheet.getColumn(2).width = 30; // Adjust width for the "Part Number" column
    worksheet.getColumn(3).width = 30; // Adjust width for the "Description" column
    for (let i = 4; i <= columns.length; i++) {
      worksheet.getColumn(i).width = 10; // Set default width for other columns
    }

    // Add data rows and adjust row height dynamically
    for (const [index, item] of filteredItems.entries()) {
      const { _id, image, ...rest } = item;
      const row = worksheet.addRow({ image: "", ...rest }); // Add 'image' field first

      // Fetch and embed the image
      if (image) {
        try {
          const base64Image = await fetchImageAsBase64(image); // Fetch image as Base64
          const imageId = workbook.addImage({
            base64: base64Image,
            extension: "png", // Adjust based on your image type
          });

          // Calculate cell dimensions based on image size
          const imageWidth = 150; // Set your desired image width
          const imageHeight = 150; // Set your desired image height

          // Adjust row height to fit the image
          row.height = imageHeight; // Convert pixel height to row height

          // Embed image
          worksheet.addImage(imageId, {
            tl: { col: 0, row: index + 1 }, // Adjust column index (0 = first column)
            ext: { width: imageWidth, height: imageHeight }, // Set image dimensions
          });
        } catch (error) {
          console.error("Failed to fetch image for item:", item, error);
        }
      }
    }

    // Save the file
    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `${selectedRegion}_inventory.xlsx`);
  };

  // Helper function to fetch image as Base64
  const fetchImageAsBase64 = async (imageIdentifier) => {
    const response = await fetch(connectionString + `/images/${imageIdentifier}?selectedRegion=${selectedRegion}`);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Get Base64 part
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const exportToCSV = () => {
    // Map the items to exclude the _id field and ensure all fields are present
    const dataToExport = filteredItems.map(item => {
      const { _id, ...rest } = item;

      // Ensure all fields are included with default values if they are empty or missing
      return {
        partnumber: rest.partnumber || '',
        description: rest.name || '',  // Change 'name' to 'description'
        location: rest.location || '',
        price: rest.price || '',
        quantity: rest.quantity || '',
        equipmenttype: rest.model || '',  // Change 'model' to 'equipmenttype'
        notes: rest.notes || '',
        image: rest.image || ''
      };
    });

    // Convert data to CSV format
    const csvData = Papa.unparse(dataToExport, {
      // You can set custom headers if you want
      header: true,  // Ensures headers are included
      columns: [
        "partnumber",
        "description",  // Column header for name changed to 'description'
        "location",
        "price",
        "quantity",
        "equipmenttype",  // Column header for model changed to 'equipmenttype'
        "notes",
        "image"
      ]
    });

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Create a link element for downloading the CSV file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${selectedRegion}_inventory.csv`;

    // Programmatically click the link to trigger the download
    link.click();
  };

  const importCSV = async (csvFile) => {
    if (!csvFile) {
      console.error('No file selected');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('csvFile', csvFile); // Append the CSV file to FormData

      const response = await axios.post(`${connectionString}/import?selectedRegion=${selectedRegion}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': sessionStorage.getItem('token')
        }
      });
      socket.emit('Item', "import item");

    } catch (error) {
      console.error('Error importing items:', error);
      setError('Error importing items');
    }
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc); // Set the selected image
    setOpenImageDialog(true);   // Open the dialog
  };

  const columns = [
    {
      field: 'image',
      flex: 1,
      headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Image', selectedRegion)}<br /></Typography>),
      renderCell: (params) => (
        params.value ? (
          <img
            src={connectionString + `/images/${params.value}?selectedRegion=${selectedRegion}`}
            alt="Item"
            style={{ width: '50%', height: '100%', objectFit: 'cover', borderRadius: '10%' }}
            onClick={() => handleImageClick(`${connectionString}/images/${params.value}?selectedRegion=${selectedRegion}`)} // Open dialog on click
            onError={(e) => {
              console.error('Image load error:', e.target.src);
              e.target.onerror = null; // Prevent infinite loop in case of repeated errors
              e.target.src = '../images/placeholder-image.jpg'; // Placeholder or default image
            }}
          />
        ) : (
          <img
            src={placeholderImage} // Placeholder or default image URL
            alt="Default"
            style={{ width: '50%', height: '100%', objectFit: 'cover', borderRadius: '10%' }}
          />
        )
      ),
    },
    {
      field: 'partnumber', flex: 2, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Part Number', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'name', flex: 2, headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Description', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'location', headerName: GetTextByRegion('Location', selectedRegion), flex: 1,
      headerAlign: 'center', align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Location', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'quantity',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Quantity', selectedRegion)}<br /></Typography>),
      renderCell: (params) => (
        <span style={{ color: params.row.quantity <= params.row.minQuantity ? 'red' : 'inherit' }}>
          {params.value}
        </span>
      )
    },
    {
      field: 'price',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Price', selectedRegion)}<br /></Typography>),
      renderCell: (params) => (
        selectedRegion === 'TAIWAN' || selectedRegion == 'CHINA' ? (
          `${params.row.price != null ? GetCurrencySymbol(selectedRegion) + params.row.price : ''}`
        ) : (
          `${params.row.price != null ? GetCurrencySymbol(selectedRegion) + params.row.price.toFixed(2) : ''}`
        )

      )
    },
    {
      field: 'model', flex: 3,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Equipment Type', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'notes', flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Notes', selectedRegion)}<br /></Typography>),
    },
    {
      field: 'actions',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{GetTextByRegion('Actions', selectedRegion)}<br /></Typography>),
      renderCell: (params) => (
        <div>
          {userRights >= roles['SALES'] && (
            <>
              <Tooltip title={GetTextByRegion("Edit", selectedRegion)} arrow>
                <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(params)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {userRights >= roles['MANAGER'] && (
                <Tooltip title={GetTextByRegion("Delete", selectedRegion)} arrow>
                  <IconButton aria-label="delete" color="secondary" onClick={() => handleDelete(params)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )
    }
  ];

  const mobileColumns = [
    { label: "Image", name: "image", type: "image", value: "image", required: false, InputLabelProps: {}, position: 'left', style: { width: '100px', height: '100px', objectFit: 'cover', marginRight: '16px' } },
    { label: "Description", name: "name", type: "text", value: "name", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Part Number", name: "partnumber", type: "text", value: "partnumber", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Location", name: "location", type: "text", value: "location", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Quantity", name: "quantity", type: "number", value: "quantity", required: true, InputLabelProps: {}, position: 'left' },
    { label: "Price", name: "price", type: "text", value: "price", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Equipment Type", name: "model", type: "text", value: "model", required: false, InputLabelProps: {}, position: 'left' },
    { label: "Notes", name: "notes", type: "text", value: "notes", required: false, InputLabelProps: {}, position: 'left' },
  ];

  return (
    <div style={{ height: windowSize.height * 0.75, width: windowSize.width * 1 }}>

      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: !windowSize.width > smallWidth ? 'flex-end' : 'center',
        flexDirection: windowSize.width <= smallWidth ? 'column' : 'row', // Column for mobile, row for larger screens
      }}>

        <div style={{
          display: 'flex',
          flexDirection: 'row', // Column for mobile, row for larger screens
        }}>
          <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={GetTextByRegion("Search Inventory...", selectedRegion)} />

          <FormControlLabel
            control={
              <Checkbox
                checked={filterByMinQuantity}
                onChange={() => setFilterByMinQuantity(!filterByMinQuantity)}
              />
            }
            label={GetTextByRegion("Show Low Quantity", selectedRegion)}
            style={{ marginLeft: '5px' }}
          />
        </div>
        {userRights >= roles['PRODUCTION'] && (
          <div style={{
            display: 'flex',
            flexDirection: 'row', // Stack buttons in column for mobile
            position: windowSize.width > smallWidth ? 'absolute' : 'relative', // Absolute on larger screens
            left: windowSize.width > smallWidth ? 0 : 'auto', // Align left on larger screens
          }}>
            <ImportCSVButton importCSV={importCSV} selectedRegion={selectedRegion} />
            <Button onClick={exportToCSV}>{GetTextByRegion("Export to CSV", selectedRegion)}</Button>
            <Button onClick={exportToExcel} ml={2}>{GetTextByRegion("Export to Excel", selectedRegion)}</Button>
          </div>
        )}


      </div>

      {windowSize.width < smallWidth ? (
        <MobileViewComponent items={filteredItems} columnData={mobileColumns} handleEditClick={handleEdit} handleDelete={handleDelete} connectionString={connectionString} selectedRegion={selectedRegion} />
      ) : (
        <CustomDataGrid rows={filteredItems} columns={columns} visibilityColumnConfig={columnVisibilityConfig} setColumnVisibility={setColumnVisibilityModel} tableName={'inventory'} />
      )}
      {/* Edit and delete Item Dialog */}
      {userRights >= roles['SALES'] && (
        <>
          {openEditDialog &&
            <EditItemForm
              open={openEditDialog}
              onClose={handleCloseEditDialog}
              item={selectedItem}
              onChange={handleInputChange}
              onSubmit={handleEditSubmit}
            />
          }
          {openDeleteDialog &&
            <DeleteConfirmationDialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onConfirm={handleDeleteConfirm}
              selectedRegion={selectedRegion}
            />
          }
        </>
      )}

      {/* Error Dialog */}
      {error &&
        <ErrorDialog
          open={!!error} // Ensure this is a boolean
          onClose={handleCloseErrorDialog}
          errorMessage={error}
        />
      }

      <ImageDialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        imageSrc={selectedImage}
      />
    </div>
  );
};

export default InventoryTable;
