import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField, CircularProgress, FormHelperText } from '@mui/material';
import axios from 'axios';

const backendString = process.env.REACT_APP_BACKEND_STRING;

// MACHINE MODEL AND CONFIG
// FETCHES INDIVIDUAL TYPE DROPDOWNS
// CAN CHOOSE AUTOFILL OR WRITE TEXT

const MachineModelDropdown = ({
  selectedRegion,
  isView,
  validationErrors,
  GetTextByRegion,
  handleChange,
  machine,
  type
}) => {
  const [dropdown, setDropdown] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (type === 'both'){
          const response1 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=machine`);
          const response2 = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=rack`);
          setDropdown([...response1.data, ...response2.data]); // Adjust based on your backend response structure
        }
        else{
          const response = await axios.get(`${backendString}/dropdown?selectedRegion=${selectedRegion}&type=${type}`);
          setDropdown(response.data); // Adjust based on your backend response structure
        }
      } catch (err) {
        setError('Failed to load dropdown');
      }
    };

    fetchOptions();
  }, [selectedRegion, type]);

  const fieldName = type === 'tube' ? 'tube' :
    type === 'rack' ? 'rack' :
      type === 'fpd' ? 'detector' :
        type === 'software' ? 'software' :
          type === 'option' ? 'options' : 'machineModelAndConfig';

  const labelName = type === 'tube' ? 'Tube' :
    type === 'rack' ? 'Rack' :
      type === 'fpd' ? 'Detector' :
        type === 'software' ? 'Software Version' :
          type === 'option' ? 'Options' : 'Machine Model and Config';

  return (
    <FormControl fullWidth error={!!validationErrors[fieldName] && !machine[fieldName]}>
      <Autocomplete
        freeSolo
        disabled={isView}
        value={machine[fieldName] || ''}
        onChange={(event, newValue) => handleChange({ target: { name: fieldName, value: newValue } })}
        onInputChange={(event, newValue) => handleChange({ target: { name: fieldName, value: newValue } })}
        options={dropdown.map((option) => option.ModelName)} // Map to the correct field
        renderInput={(params) => (
          <TextField
            {...params}
            label={GetTextByRegion(`${labelName}`, selectedRegion)}
            error={!!validationErrors[fieldName] && !machine[fieldName]}
            helperText={validationErrors[fieldName] && !machine[fieldName] ? validationErrors[fieldName] : ''}
          />
        )}
      />
    </FormControl>
  );
};

export default MachineModelDropdown;
