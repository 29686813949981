import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';
import ItemForm from '../components/Inventory/ItemForm';
import InventoryTable from '../components/Inventory/InventoryTable';
import InOutPopup from '../components/Inventory/InOutPopup';
import HistoryPopup from '../components/Inventory/HistoryPopup';
import InventoryCheckPopup from '../components/Inventory/InventoryCheckPopup';
import io from 'socket.io-client';
import roles from '../hooks/Roles';
import { GetTextByRegion } from '../hooks/Translation';
import { useUser, useValidateRegions } from '../hooks/UserContext';
import isEqual from 'lodash/isEqual';

const Inventory = () => {
  const [isItemPopupOpen, setIsItemPopupOpen] = useState(false);
  const [items, setItems] = useState([]); // State to manage inventory items
  const [isInOutOpen, setIsInOutOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isInventoryCheckOpen,setIsInventoryCheckOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterByMinQuantity, setFilterByMinQuantity] = useState(false);
  const validateSelectedRegion = ["USA", "MEXICO", "EU", "TAIWAN"];
  const { selectedRegion, user: { userRights }, refreshUser } = useUser();


  useValidateRegions(selectedRegion, validateSelectedRegion);
  const prevSelectedRegionRef = useRef();
  useEffect(() => {
    refreshUser().then(() => {
      const currentRegion = selectedRegion;
      if (prevSelectedRegionRef.current && !isEqual(prevSelectedRegionRef.current, currentRegion)) {
        setIsItemPopupOpen(false);
        setIsInOutOpen(false);
        setOpenEditDialog(false);
        setOpenDeleteDialog(false);
        setFilterByMinQuantity(false);
      }
      prevSelectedRegionRef.current = currentRegion;
    });
  }, [refreshUser, selectedRegion, isItemPopupOpen, isInOutOpen, isHistoryOpen, openEditDialog, openDeleteDialog, filterByMinQuantity]);

  const backendString = process.env.REACT_APP_BACKEND_STRING;
  const socket = io(backendString);

  // Function to handle adding new item to inventory
  const handleAddItem = (newItem) => {
    // setItems([...items, newItem]);
    socket.emit('Item', newItem);
  };

  // Function to handle inbound item
  const handleInboundItem = (updatedItem) => {
    // Update items in state for inbound action
    socket.emit('Item', updatedItem);
  };

  // Function to handle outbound item
  const handleOutboundItem = (updatedItem) => {
    // Update items in state for inbound action
    socket.emit('Item', updatedItem);

  };

  // Handlers for opening and closing inbound/outbound popup
  const handleInOutOpen = () => {
    setIsInOutOpen(true);
  };

  const handleInOutClose = () => {
    setIsInOutOpen(false);
  };

  const handleHistoryOpen = () => {
    setIsHistoryOpen(true);
  };

  const handleHistoryClose = () => {
    setIsHistoryOpen(false);
  };

  const handleInventoryCheckOpen = () => {
    setIsInventoryCheckOpen(true);
  };

  const handleInvenotryCheckClose = () => {
    setIsInventoryCheckOpen(false);
  };

  return (
    <div>
      <Box display="flex" gap="10px">
        {userRights >= roles['PRODUCTION'] && (
          <>
            <Button variant="contained" color="primary" onClick={() => setIsItemPopupOpen(true)}>
              {GetTextByRegion("Add New Item", selectedRegion)}
            </Button>
            <Button variant="contained" color="primary" onClick={handleInOutOpen}>
              {GetTextByRegion("Inbound/Outbound", selectedRegion)}
            </Button>
            <Button variant="contained" color="primary" onClick={handleHistoryOpen}>
              {GetTextByRegion("History Log", selectedRegion)}
            </Button>
            <Button variant="contained" color="primary" onClick={handleInventoryCheckOpen}>
              {GetTextByRegion("Inventory Check", selectedRegion)}
            </Button>
          </>
        )}
      </Box>

      {/* ItemForm Popup */}
      {isItemPopupOpen && userRights >= roles['PRODUCTION'] && (
        <ItemForm onAdd={handleAddItem} onClose={() => setIsItemPopupOpen(false)} />
      )}

      {/* InOutPopup */}
      {isInOutOpen && userRights >= roles['PRODUCTION'] && (
        <InOutPopup
          open={isInOutOpen}
          onClose={handleInOutClose}
          onInbound={handleInboundItem} // Pass inbound handler
          onOutbound={handleOutboundItem} // Pass outbound handler
        />
      )}

      {/* History Popup */}
      {isHistoryOpen && userRights >= roles['PRODUCTION'] && (
        <HistoryPopup
          open={isHistoryOpen}
          onClose={handleHistoryClose}
        />
      )}

      {/* History Popup */}
      {isInventoryCheckOpen && userRights >= roles['PRODUCTION'] && (
        <InventoryCheckPopup
          open={isInventoryCheckOpen}
          onClose={handleInvenotryCheckClose}
          items={items}
          setItems={setItems}
        />
      )}

      {/* InventoryTable */}
      <InventoryTable
        items={items}
        setItems={setItems}
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        filterByMinQuantity={filterByMinQuantity}
        setFilterByMinQuantity={setFilterByMinQuantity}
      />
    </div>
  );
};

export default Inventory;
