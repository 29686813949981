import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../../images/scienscopeLogo.png';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ListItemIcon, Typography, Menu, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import USAFLAG from '../../images/usa.jpg';
import MEXICOFLAG from '../../images/mexico.png';
import EUFLAG from '../../images/EU.png';
import CHINAFLAG from '../../images/china.png';
import TAIWANFLAG from '../../images/taiwan.png';
import ProfileMenu from './ProfileMenu'; // Import the ProfileMenu component
import SettingsMenu from './SettingsMenu'; // Import the ProfileMenu component
import { maxWidth } from '../../hooks/useWindowSize';
import roles from '../../hooks/Roles';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser, getStoredRegion, setStoredRegion } from '../../hooks/UserContext';

const FlagIcon = styled('img')({
  width: 24,
  height: 16,
  marginRight: 8,
});

const regionFlags = {
  USA: USAFLAG,
  MEXICO: MEXICOFLAG,
  EU: EUFLAG,
  CHINA: CHINAFLAG,
  TAIWAN: TAIWANFLAG,
};

const highlightStyle = {
  backgroundColor: '#FFFFFF', // Change this to the color you want for the active tab
  fontWeight: 'bold',
};

const ExtraMenu = ({ isSmallScreen, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem role="none" >
        <ListItemButton
          role="none"
          onClick={handleClick}
          sx={{
            height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
          }}
        >
          Extra
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/todo"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          Todo
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/Q&A"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          Q&A
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/remoteservice"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          Remote Service
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/quotegenerator"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          Quote Generator
        </MenuItem>
      </Menu>
    </>
  );
};

const ProductionMenu = ({ isSmallScreen, onClick, selectedRegion }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem role="none" >
        <ListItemButton
          role="none"
          onClick={handleClick}
          sx={{
            height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
          }}
        >
          {GetTextByRegion("Production", selectedRegion)}
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/chinaproduction"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          {GetTextByRegion("Production-China", selectedRegion)}
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/overseaschinaproduction"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          {GetTextByRegion("Production (Overseas)", selectedRegion)}
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/euchinaproduction"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          {GetTextByRegion("Production-EU", selectedRegion)}
        </MenuItem>
      </Menu>
    </>
  );
};

const PartRequestLogMenu = ({ isSmallScreen, onClick, selectedRegion }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem role="none" >
        <ListItemButton
          role="none"
          onClick={handleClick}
          sx={{
            height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
          }}
        >
          {GetTextByRegion("Part Request Log", selectedRegion)}
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/chinapartrequestlog"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          {GetTextByRegion("Part Request Log - China", selectedRegion)}
        </MenuItem>
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/partrequestlog"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          {GetTextByRegion("Part Request Log - Overseas", selectedRegion)}
        </MenuItem>
        
        <MenuItem
          onClick={() => { handleClose(); onClick(); }}
          component={Link}
          to="/eupartrequestlog"
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            padding: '10px 30px',
          }}>
          {GetTextByRegion("Part Request Log - EU", selectedRegion)}
        </MenuItem>
      </Menu>
    </>
  );
};

const PartRequestsMenu = ({ isSmallScreen, onClick, selectedRegion, userRights }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem role="none" >
        <ListItemButton
          role="none"
          onClick={handleClick}
          sx={{
            height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
          }}
        >
          {GetTextByRegion("Part Requests", selectedRegion)}
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {userRights >= roles['SERVICE'] && selectedRegion !== "CHINA" && (
          <MenuItem
            onClick={() => { handleClose(); onClick(); }}
            component={Link}
            to="/itemrequest"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              padding: '10px 30px',
            }}>
            {GetTextByRegion("Part Requests", selectedRegion)} - {selectedRegion}
          </MenuItem>
        )}
        {userRights >= roles['MODERATOR'] && selectedRegion === "USA" && (
          <MenuItem
            onClick={() => { handleClose(); onClick(); }}
            component={Link}
            to="/partrequestlog"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              padding: '10px 30px',
            }}>
            {GetTextByRegion("Part Request Log - Overseas", selectedRegion)}
          </MenuItem>
        )}

        {userRights >= roles['MODERATOR'] && selectedRegion === "EU" && (
          <MenuItem
            role="menuitem"
            component={Link}
            onClick={() => { handleClose(); onClick(); }}
            to="/eupartrequestlog"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              padding: '10px 30px',
            }}>
            {GetTextByRegion("Part Request Log - EU", selectedRegion)}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};


export default function Navbar() {
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation();
  const isSmallScreen = useMediaQuery(`(max-width: ${maxWidth}px)`); // Adjust the width as needed
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const { selectedRegion, setSelectedRegion, resetUser, user: { userRights, region }, error } = useUser();
  useEffect(() => {
    if (error && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [error, location.pathname, navigate]);

  useEffect(() => {
    if (getStoredRegion() !== selectedRegion) {
      setSelectedRegion(getStoredRegion());
    }
  }, [selectedRegion, setSelectedRegion]);

  //handles profile dropdown
  const handleLogout = () => {
    toggleDrawer(false);
    handleProfileMenuClose();
    resetUser();
    navigate('/login');
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
    handleProfileMenuClose();
    handleSettingsMenuClose();
  };

  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const handleSettingsMenuOpen = (event) => {
    setSettingsMenuAnchor(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchor(null);
  };

  // Define a new component for region dropdown
  const RegionDropdown = () => {
    const handleRegionChange = (event) => {
      setSelectedRegion(event.target.value);
      setStoredRegion(event.target.value);
      setDrawerOpen(false);
    };



    return (
      <div>
        <FormControl variant="outlined" >
          <Select
            value={selectedRegion}
            onChange={handleRegionChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Select Region' }}
            renderValue={(selected) => {
              if (!selected) {
                return <em>Select Region</em>;
              }
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FlagIcon src={regionFlags[selected]} alt={selected} />
                  {selected}
                </Box>
              );
            }}

          >
            <MenuItem value="" disabled>
              <em>Select Region</em>
            </MenuItem>
            {region?.map((item) => (
              <MenuItem key={item} value={item}>
                <ListItemIcon>
                  <FlagIcon src={regionFlags[item]} alt={item} />
                </ListItemIcon>
                <Typography variant="inherit">{item}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const menuItems = (
    <>
      <ListItem role="none">
        <ListItemButton
          role="menuitem"
          component={Link}
          to="/"
          onClick={toggleDrawer(false)}
          style={location.pathname === '/' ? highlightStyle : {}}
        >
          <ListItemDecorator>
            <img src={Logo} className='logo-img' style={{ height: '50px', width: 'auto' }} alt="Logo" />
          </ListItemDecorator>
        </ListItemButton>
      </ListItem>
      {userRights >= roles['SERVICE'] && selectedRegion !== "CHINA" && (
        <>
          {
            //Inventory////////////////////////////////////////////////////////////////
          }
          <ListItem role="none">
            <ListItemButton
              role="menuitem"
              component={Link}
              to="/inventory"
              onClick={toggleDrawer(false)}
              style={location.pathname === '/inventory' ? highlightStyle : {}}
              sx={{
                height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
              }}
            >
              {GetTextByRegion("Inventory", selectedRegion)}
            </ListItemButton>
          </ListItem>
        </>
      )}
      {userRights >= roles['SALESASSISTANT'] && selectedRegion === "CHINA" && (
        <ProductionMenu isSmallScreen={isSmallScreen} onClick={toggleDrawer(false)} selectedRegion={selectedRegion} />
      )}
      {userRights >= roles['SERVICE'] && selectedRegion !== "CHINA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/machine"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/machine' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Machines", selectedRegion)}
          </ListItemButton>
        </ListItem>
      )
      }
      {userRights >= roles['MODERATOR'] && selectedRegion === "USA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/overseaschinaproduction"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/overseaschinaproduction' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Production (Overseas)", selectedRegion)}
          </ListItemButton>
        </ListItem>
      )}
      {userRights >= roles['MODERATOR'] && selectedRegion === "EU" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/euchinaproduction"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/euchinaproduction' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Production-EU", selectedRegion)}
          </ListItemButton>
        </ListItem>
      )}


      {userRights >= roles['SERVICE'] && selectedRegion !== "CHINA" && (
        <PartRequestsMenu isSmallScreen={isSmallScreen} onClick={toggleDrawer(false)} selectedRegion={selectedRegion} userRights={userRights} />
      )}

      {userRights >= roles['PRODUCTION'] && selectedRegion !== "CHINA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/reports"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/reports' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Reports", selectedRegion)}
          </ListItemButton>
        </ListItem>
      )}
      {(userRights === roles["SALES"] || userRights >= roles["MODERATOR"]) && selectedRegion === "USA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/service"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/service' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Service Orders", selectedRegion)}
          </ListItemButton>
        </ListItem>
      )}

      {userRights >= roles['MODERATOR'] && selectedRegion === "CHINA" && (
        <PartRequestLogMenu isSmallScreen={isSmallScreen} onClick={toggleDrawer(false)} selectedRegion={selectedRegion} />
      )}

      {userRights >= roles['SERVICE'] && selectedRegion === "USA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/calendar"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/calendar' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Calendar", selectedRegion)}
          </ListItemButton>
        </ListItem>
      )}




      {userRights >= roles['MODERATOR'] && selectedRegion === "CHINA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/chinareports"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/chinareports' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Reports", selectedRegion)}({GetTextByRegion("China", selectedRegion)})

          </ListItemButton>
        </ListItem>
      )}
      {userRights >= roles['MODERATOR'] && selectedRegion === "CHINA" && (
        <ListItem role="none">
          <ListItemButton
            role="menuitem"
            component={Link}
            to="/chinaitemrequest"
            onClick={toggleDrawer(false)}
            style={location.pathname === '/chinaitemrequest' ? highlightStyle : {}}
            sx={{
              height: isSmallScreen ? '50px' : 'auto', // Adjust height based on screen size
            }}
          >
            {GetTextByRegion("Part Requests", selectedRegion)}

          </ListItemButton>
        </ListItem>
      )}
      {userRights >= roles['SERVICE'] && selectedRegion === "USA" && (
        <ExtraMenu isSmallScreen={isSmallScreen} onClick={toggleDrawer(false)} />
      )}
      {userRights >= roles['SERVICE'] && (
        <ListItem role="none" sx={{ marginLeft: isSmallScreen ? '0' : 'auto', paddingRight: '30px', display: 'flex', alignItems: 'center' }}>
          {userRights >= roles['MODERATOR'] && (
            <>
              <IconButton color="inherit" onClick={handleSettingsMenuOpen} >
                <SettingsIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <Menu
                anchorEl={settingsMenuAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(settingsMenuAnchor)}
                onClose={handleSettingsMenuClose}
              >
                <SettingsMenu onClick={toggleDrawer(false)} selectedRegion={selectedRegion} />
              </Menu>
            </>
          )}
          <RegionDropdown />
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            sx={{ fontSize: 40 }} // Adjust the size as needed
          >
            <AccountCircle fontSize="inherit" />
          </IconButton>
          <Menu
            anchorEl={profileMenuAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(profileMenuAnchor)}
            onClose={handleProfileMenuClose}
          >
            <ProfileMenu onClick={toggleDrawer(false)} onLogout={handleLogout} isLoggedIn={userRights >= roles['SERVICE']} selectedRegion={selectedRegion} />
          </Menu>
        </ListItem>
      )}
    </>
  );

  return (
    <Box sx={{ minHeight: 90 }}>
      {isSmallScreen ? (
        <>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ fontSize: '3rem' }} // Adjust the font size to make the icon larger
          >
            <MenuIcon fontSize="inherit" />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            {/* <List role="menubar" sx={{ width: 250 }}> */}
            <List role="menubar" sx={{
              textDecoration: 'none',
              color: 'inherit',
              padding: '10px 30px',
            }}>
              {menuItems}
            </List>
          </Drawer>
        </>
      ) : (
        <List
          role="menubar"
          orientation="horizontal"
          sx={{
            '--List-radius': '8px',
            '--List-padding': '4px',
            '--List-gap': '0px',
            '--ListItem-gap': '0px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '6px',
            '@media (max-width: 1850px)': {
              gap: '3px',
            },
            '@media (max-width: 1800px)': {
              gap: '0px',
            },
          }}
        >
          {menuItems}
        </List>
      )}
    </Box>
  );
}
