import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  FormHelperText,
  Typography,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit'
import PartNumberTextField from '../PartNumberTextField';
import ErrorDialog from '../ErrorDialog';
import { useTheme } from '@mui/material/styles';
import LoadingSpinner from '../LoadingSpinner';
import ModelDropdown from '../ModelDropdown';
import AddIcon from '@mui/icons-material/Add';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import { format } from 'date-fns';
import roles from '../../hooks/Roles';
import ShippingFormFields from './ShippingFormFields';
import { CheckInventory } from './CheckInventory';
import IconRejectButton from '../IconButtons/IconRejectButton';
import IconAcceptButton from '../IconButtons/IconAcceptButton';
import IconAcceptUnfilledButton from '../IconButtons/IconAcceptUnfilledButton';
import IconRejectUnfilledButton from '../IconButtons/IconRejectUnfilledButton';


const backendString = process.env.REACT_APP_BACKEND_STRING;

const ItemRequestForm = ({ open, onClose, onRequestSubmit, onRequestSave, editItem, isChina = false, tabValue = 0, onAccept, onReject, onUpdateStatus, onInventoryUpdate, onMarkComplete, onSendToChina }) => {
  const theme = useTheme();
  const { selectedRegion, user: { username, userRights }, } = useUser();
  const [numPartNumbers, setNumPartNumbers] = useState(editItem ? editItem.partNumber.length : 1);
  const [numTrackingNumbers, setNumTrackingNumbers] = useState(editItem ? editItem.trackingNumber.length : 1);


  const defaultFormValues = {
    submittedDate: format(new Date(), 'yyyy-MM-dd'),
    partNumber: [''],
    quantity: ['1'],
    customerName: '',
    model: '',
    serial: '',
    warranty: false,
    shippingAddress: '',
    shippingAccount: '',
    shippingMethod: selectedRegion === "EU" || selectedRegion === "TAIWAN" ? 'NA' : '',
    notes: '',
    submittedBy: username,
    contactName: '',
    contactPhone: '',
    status: 'submitted',
    region: selectedRegion,
    trackingNumber: [''],
    approvalNotes: '',
    requestId: username + "_" + format(new Date(), 'yyyyMMddHHmmss'),
    shippingNotes: '',
    shippingCompany: [''],
    trackingNotes: [''],
    purpose: '',
    isHighValue: false,
  };

  const [formData, setFormData] = useState(defaultFormValues);
  const [originalEdit, setOriginalEdit] = useState(defaultFormValues);

  const resetFormData = useCallback(() => {
    setFormData(defaultFormValues);
  }, [username, selectedRegion]); // Assuming `username` and `selectedRegion` can change


  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inventoryErrors, setInventoryErrors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(editItem ? false : true);
  const [tab, setTab] = useState(0); // State for tab selection
  const [isEditMode, setIsEditMode] = useState(isNew ? true : false);
  const [isShippingNotes, setIsShippingNotes] = useState(tabValue === 3 || tabValue === 5);

  useEffect(() => {
    if (isNew) {
      setIsShippingNotes(false);
    }
    if (editItem) {
      setFormData({
        submittedDate: editItem.submittedDate || format(new Date(), 'yyyy-MM-dd'),
        partNumber: editItem.partNumber || [''],
        quantity: editItem.quantity || ['1'],
        customerName: editItem.customerName || '',
        model: editItem.model || '',
        serial: editItem.serial || '',
        warranty: editItem.warranty || false,
        shippingAddress: editItem.shippingAddress || '',
        shippingAccount: editItem.shippingAccount || '',
        shippingMethod: editItem.shippingMethod || '',
        notes: editItem.notes || '',
        submittedBy: editItem.submittedBy || username, // Keep the submittedBy field set to the current user
        contactName: editItem.contactName || '',
        contactPhone: editItem.contactPhone || '',
        status: editItem.status || 'submitted',
        region: editItem.region || '',
        trackingNumber: editItem.trackingNumber || [''],
        approvalNotes: editItem.approvalNotes || '',
        shippingNotes: editItem.shippingNotes || '',
        shippingCompany: editItem.shippingCompany || [''],
        trackingNotes: editItem.trackingNotes || [''],
        purpose: editItem.purpose || '',
        isHighValue: editItem.isHighValue || false,
        requestId: editItem.requestId || username + "_" + format(new Date(), 'yyyyMMddHHmmss'),
      });
      setOriginalEdit({
        submittedDate: editItem.submittedDate || '',
        partNumber: editItem.partNumber || [''],
        quantity: editItem.quantity || ['1'],
        customerName: editItem.customerName || '',
        model: editItem.model || '',
        serial: editItem.serial || '',
        warranty: editItem.warranty || false,
        shippingAddress: editItem.shippingAddress || '',
        shippingAccount: editItem.shippingAccount || '',
        shippingMethod: editItem.shippingMethod || '',
        notes: editItem.notes || '',
        submittedBy: editItem.submittedBy || username, // Keep the submittedBy field set to the current user
        contactName: editItem.contactName || '',
        contactPhone: editItem.contactPhone || '',
        status: editItem.status || 'submitted',
        region: editItem.region || '',
        trackingNumber: editItem.trackingNumber || [''],
        approvalNotes: editItem.approvalNotes || '',
        shippingNotes: editItem.shippingNotes || '',
        shippingCompany: editItem.shippingCompany || [''],
        trackingNotes: editItem.trackingNotes || [''],
        purpose: editItem.purpose || '',
        isHighValue: editItem.isHighValue || false,
        requestId: editItem.requestId || username + "_" + format(new Date(), 'yyyyMMddHHmmss'),
      });
      setNumPartNumbers(editItem.partNumber.length);
      setNumTrackingNumbers(editItem.trackingNumber.length === 0 ? 1 : editItem.trackingNumber.length);
    }
    else resetFormData();
  }, [editItem, username, resetFormData]);

  const validateInventory = useCallback(async () => {
    if (!editItem) {
      return;
    }

    try {
      const partNumbers = editItem.partNumber;
      const quantities = editItem.quantity;

      // Ensure CheckInventory returns an array of error objects
      const validationErrors = await CheckInventory(partNumbers, quantities, editItem.region);
      if (Array.isArray(validationErrors)) {
        setInventoryErrors(validationErrors);
      } else {
        console.error('Expected an array of errors from CheckInventory');
        setInventoryErrors([]);
      }
    } catch (error) {
      console.error('Error validating inventory:', error);
      setInventoryErrors([]);
    }
  }, [editItem]);

  useEffect(() => {
    if (!isEditMode && editItem) {
      validateInventory();
    }
  }, [open, validateInventory]);


  const validate = () => {
    const requiredFields = ['submittedDate', 'customerName', 'model', 'serial', 'shippingAddress', 'shippingMethod', 'contactName', 'contactPhone', 'purpose'];
    const newErrors = requiredFields.reduce((acc, field) => ({
      ...acc,
      [field]: formData[field] ? '' : GetTextByRegion(`${field.replace(/([A-Z])/g, '$1')}`, selectedRegion) + GetTextByRegion('is required', selectedRegion)
    }), {});
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;

    if (!name) {
      return;
    }

    if (name.startsWith('partNumber')) {
      const index = parseInt(name.split('-')[1], 10);
      const newPartNumbers = [...formData.partNumber];
      newPartNumbers[index] = value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        partNumber: newPartNumbers,
      }));
    }
    if (name.startsWith('quantity')) {
      const index = parseInt(name.split('-')[1], 10);
      const newQuantity = [...formData.quantity];
      newQuantity[index] = value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        quantity: newQuantity,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleNumPartNumbersChange = (event) => {
    const value = Math.max(1, parseInt(event.target.value, 10) || 1);
    const partNumbers = [
      ...formData.partNumber.slice(0, value),
      ...Array(Math.max(0, value - formData.partNumber.length)).fill('')
    ];

    const quantities = [
      ...formData.quantity.slice(0, value),
      ...Array(Math.max(0, value - formData.quantity.length)).fill('1')
    ];

    setNumPartNumbers(value);
    setFormData((prev) => ({ ...prev, partNumber: partNumbers, quantity: quantities }));
  };

  // Updated function to handle a direct number
  const handleNumTrackingNumbersChange = (value) => {
    value = Math.max(1, value);
    const trackingNumbers = [
      ...formData.trackingNumber.slice(0, value),
      ...Array(value - formData.trackingNumber.length).fill('')
    ];
    setNumTrackingNumbers(value);
    setFormData((prev) => ({ ...prev, trackingNumber: trackingNumbers }));
  };

  const handleShippingCompanyChange = (index, event) => {
    const newShippingCompanies = [...formData.shippingCompany];
    newShippingCompanies[index] = event.target.value;
    setFormData({ ...formData, shippingCompany: newShippingCompanies });
  };

  const handleTrackingNotesChange = (index, event) => {
    const newShippingCompanies = [...formData.trackingNotes];
    newShippingCompanies[index] = event.target.value;
    setFormData({ ...formData, trackingNotes: newShippingCompanies });
  };

  const handleTrackingNumberChange = (index, event) => {
    const newTrackingNumbers = [...formData.trackingNumber];
    newTrackingNumbers[index] = event.target.value;
    setFormData((prev) => ({ ...prev, trackingNumber: newTrackingNumbers }));
  };

  const updateItemStatus = async (status, region) => {
    try {
      const updatedFormData = {
        ...formData,
        trackingNumber: formData.trackingNumber.filter(tracking => tracking.trim() !== ''),
        shippingCompany: formData.shippingCompany.filter(tracking => tracking.trim() !== ''),
        trackingNotes: formData.trackingNotes.filter(tracking => tracking.trim() !== ''),
        status: status,
      };

      // Update existing item
      const connectionString = backendString + `/item-requests/${editItem._id}?selectedRegion=${region}`;
      await axios.put(connectionString, updatedFormData, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
        },
      });
    } catch (error) {
      throw error; // Rethrow the error to handle it in the calling function
    }
  };


  const handleSave = async () => {

    try {
      // Update existing item
      let connectionString = backendString + `/item-requests/${editItem._id}?selectedRegion=${editItem.region}`;
      await axios.put(connectionString, formData, {
        headers: {
          'Authorization': sessionStorage.getItem('token')
        }
      });
      onRequestSave();
    } catch (error) {
      setError(error.response?.data?.error);
    }
    finally {
      handleCancel(); // Reset form and close dialog after submission
    }
  }

  async function ensureRequestId(maxWaitTime = 5000) { // Maximum wait time in milliseconds
    const startTime = Date.now();

    while (!formData.requestId) {
      console.log("Waiting for requestId to be set...");
      await new Promise(resolve => setTimeout(resolve, 50)); // Polling delay

      // Check if we've exceeded the maximum wait time
      if (Date.now() - startTime > maxWaitTime) {
        console.error("Timed out waiting for requestId to be set.");
        throw new Error("requestId was not set within the allowed time.");
      }
    }
  }

  const handleSubmit = async () => {
    if (isShippingNotes) {
      try {//china adds shipping notes
        setLoading(true);
        await updateItemStatus('in process', editItem.region);

        const response = await axios.get(
          `${backendString}/users/email/CHINA_QA_EMAIL`
        );

        //send email saying declined
        if (response.data.email) {

          const requestData={ 
            serial:formData.serial, 
            partNumber: formData.partNumber, 
            email: response.data.email, 
            requestId: formData.requestId,
          };

          await axios.post(backendString + `/send-email/notFinished?selectedRegion=${editItem.region}`, requestData);
        }

      } catch (error) {
        setError(error.response?.data?.error);

        try { // case when POST passes but email fails
          await updateItemStatus('in process', editItem.region);
        } catch (error) {
          setError(error.response?.data?.error);
        }
      } finally {
        handleCancel(); // Reset form and close dialog after submission
        setLoading(false);
      }
    } else if (isChina) {
      try {//send back to the person that submitted
        setLoading(true);
        await updateItemStatus('finished', editItem.region);


        // Get the submitted by user's email
        const emailresponse = await axios.get(
          `${backendString}/users/email/${editItem.submittedBy}`
        );

        const response1 = await axios.get(
          `${backendString}/users/email/${
            selectedRegion === 'USA'
              ? 'US_PRODUCTION_EMAIL'
              : selectedRegion === 'EU'
              ? 'EU_PRODUCTION_EMAIL'
              : selectedRegion === 'TAIWAN'
              ? 'TAIWAN_PRODUCTION_EMAIL'
              : 'US_PRODUCTION_EMAIL'
          }`
        );

        await ensureRequestId();

        // Send email saying approved
        if (emailresponse.data.email) {
          const emailData = {
            email: emailresponse.data.email,
            serial: editItem.serial,
            partNumber: editItem.partNumber,
            requestId: editItem.requestId || "(Request ID not found. Check Scienscope Circle)",
            emailcc: response1.data.email
          };

          await axios.post(backendString + `/send-email/finished?selectedRegion=${editItem.region}`, emailData);
        }

      } catch (error) {
        setError(error.response?.data?.error);

        try { // case when POST passes but email fails
          await updateItemStatus('in process', editItem.region);
        } catch (error) {
          setError(error.response?.data?.error);
        }
      } finally {
        handleCancel(); // Reset form and close dialog after submission
        setLoading(false);
      }
    } else { // case when not in China and submitting a new form
      setFormSubmitted(true);
      if (!validate()) {
        return; // Prevent submission if validation fails
      }
      setLoading(true);

      try {
        if (editItem) {

          if (tabValue === 4) {
            //resubmit declined forms
            await updateItemStatus('pending', selectedRegion);
          }
          else {
            try {
              // Update existing item
              let connectionString = backendString + `/item-requests/${editItem._id}?selectedRegion=${editItem.region}`;
              await axios.put(connectionString, formData, {
                headers: {
                  'Authorization': sessionStorage.getItem('token')
                }
              });
              onRequestSave();
            } catch (error) {
              setError(error.response?.data?.error);
            }
            finally {
              handleCancel(); // Reset form and close dialog after submission
            }
          }

        } else {
          try {
            // Create new item
            await axios.post(`${backendString}/item-requests/?selectedRegion=${selectedRegion}`, formData, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });

            const response = await axios.get(
              `${backendString}/users/email/${
                selectedRegion === 'USA'
                  ? 'US_PRODUCTION_EMAIL'
                  : selectedRegion === 'EU'
                  ? 'EU_PRODUCTION_EMAIL'
                  : selectedRegion === 'TAIWAN'
                  ? 'TAIWAN_PRODUCTION_EMAIL'
                  : 'US_PRODUCTION_EMAIL'
              }`
            );
            //send email saying declined
            if (response.data.email) {
              await ensureRequestId();

              const requestData = {
                requestId: formData.requestId || "(Request ID not found. Check Scienscope Circle)",
                email: response.data.email,
              }

              await axios.post(backendString + `/send-email/submitted?selectedRegion=${selectedRegion}`, requestData);
            }
          }
          catch (error) {
            console.log(error);
            setError(error.response?.data?.error);
          }
        }
      } catch (error) {
        setError(error.response?.data?.error);

        try { // case when POST passes but email fails
          if (editItem) {
            // Update existing item
            let connectionString = backendString + `/item-requests/${editItem._id}?selectedRegion=${selectedRegion}`;
            await axios.put(connectionString, originalEdit, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });
          } else {
            let connectionString = backendString + `/item-requests/${editItem._id}?selectedRegion=${selectedRegion}`;
            // Delete the item from the table
            await axios.delete(connectionString, {
              headers: {
                'Authorization': sessionStorage.getItem('token')
              }
            });
          }
        } catch (error) {
          setError(error.response?.data?.error);
        }
      } finally {
        handleCancel(); // Reset form and close dialog after submission
        setLoading(false);
      }
    }
    onRequestSubmit();
  };


  const handleCloseErrorDialog = () => {
    setError(null);
  };

  const handleCancel = () => {
    resetFormData();
    setNumPartNumbers(1);
    setNumTrackingNumbers(1);
    setErrors({});
    setFormSubmitted(false);
    setIsEditMode(false);
    onClose();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const getShippingMethodOptions = (region) => {
    const options = [
      { value: "Over-night", label: GetTextByRegion("Over-night", region) },
      { value: "NA", label: GetTextByRegion("NA", region) },
    ];

    if (region !== "EU" && region !== "Taiwan") {
      options.push(
        { value: "1 day", label: GetTextByRegion("1 day", region) },
        { value: "2 day", label: GetTextByRegion("2 days", region) },
        { value: "3 day", label: GetTextByRegion("3 days", region) },
        { value: "Ground", label: GetTextByRegion("Ground", region) }
      );
    }

    return options;
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="lg">
        <DialogTitle>
          {editItem
            ? GetTextByRegion("Edit Spare Part Request", selectedRegion)
            : GetTextByRegion("Submit Spare Part Request", selectedRegion)}
        </DialogTitle>
        <DialogContent>
          {/* <Tabs 
          value={tab} 
          onChange={handleTabChange}>
            <Tab label={GetTextByRegion("Spare Part", selectedRegion)} />
            {selectedRegion === 'USA' && (
              <Tab label={GetTextByRegion("Inhouse",selectedRegion)} />
            )}
          </Tabs> */}
          {!isChina && !editItem && (
            <Typography variant='caption' sx={{ lineHeight: '1' }}>
              Dear All, <br />
              This Form is created for the Production/Service division of Scienscope.  The Form shall be filled out if: <br />
              1. Requesting spare parts for your customer whose Scienscope products are under warranty. <br />
              2. The request of spare parts for a customer whose Scienscope products are out of warranty but the request is not supported by a Sales Order.<br />
              3. Scienscope shall provide a loaner to the customer for a quick remedy of the issues while Scienscope is trying to resolve the issues.<br />
              4. Requesting spare parts within the Scienscope organization, especially from subdivisions to the head office and from the US office to Kunshan factory for service stocks. <br />

              Please do not fill out the Form for the out-of-warranty case that the customer shall purchase or rent a part or parts.  In that case,  a service quote shall be sent to the customer and the parts will be shipped upon receipt of a Sales Order.
            </Typography>
          )}
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} sm={6} mt={2}>
              <Box>
                <TextField
                  label={GetTextByRegion("Submitted By", selectedRegion)}
                  name="submittedBy"
                  value={formData.submittedBy}
                  onChange={handleChange}
                  fullWidth
                  required
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mt={2}>
                <TextField
                  label={GetTextByRegion("Submitted Date", selectedRegion)}
                  type="date"
                  name="submittedDate"
                  value={formData.submittedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  disabled={isChina || isShippingNotes || !isEditMode}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.submittedDate && !formData.submittedDate}
                  helperText={formData.submittedDate ? '' : errors.submittedDate}
                  inputProps={{
                    style: { color: !formData.submittedDate && errors.submittedDate ? theme.palette.error.main : 'inherit' }
                  }}
                />
              </Box>
            </Grid>

            {/* only show in spare part */}

            {tab === 0 && (
              <>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Request ID", selectedRegion)}
                      name="shippingAddress"
                      value={formData.requestId}
                      onChange={handleChange}
                      fullWidth
                      required
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <ModelDropdown
                      value={formData.model}
                      name="model"
                      onChange={handleChange}
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      selectedRegion={formData.region}
                      errors={errors.model}
                      helperText={formData.model ? '' : errors.model}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Serial Number", selectedRegion)}
                      name="serial"
                      value={formData.serial}
                      onChange={handleChange}
                      fullWidth
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      error={!!errors.serial && !formData.serial}
                      helperText={formData.serial ? '' : errors.serial}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Shipping Address", selectedRegion)}
                      name="shippingAddress"
                      value={formData.shippingAddress}
                      onChange={handleChange}
                      fullWidth
                      multiline
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      error={!!errors.shippingAddress && !formData.shippingAddress}
                      helperText={formData.shippingAddress ? '' : errors.shippingAddress}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl
                      fullWidth
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      error={!!errors.shippingMethod && !formData.shippingMethod}
                    >
                      <InputLabel>{GetTextByRegion("Shipping Method", selectedRegion)}</InputLabel>
                      <Select
                        label="Shipping Method"
                        name="shippingMethod"
                        value={formData.shippingMethod}
                        onChange={handleChange}
                      >
                        {getShippingMethodOptions(selectedRegion).map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.shippingMethod && !formData.shippingMethod && (
                        <FormHelperText>{errors.shippingMethod}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>


                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Shipping Account", selectedRegion)}
                      name="shippingAccount"
                      value={formData.shippingAccount}
                      onChange={handleChange}
                      disabled={isChina || isShippingNotes || !isEditMode}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Customer Name", selectedRegion)}
                      name="customerName"
                      value={formData.customerName}
                      onChange={handleChange}
                      fullWidth
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      error={!!errors.customerName && !formData.customerName}
                      helperText={formData.customerName ? '' : errors.customerName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Contact Name", selectedRegion)}
                      name="contactName"
                      value={formData.contactName}
                      onChange={handleChange}
                      fullWidth
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      error={!!errors.contactName && !formData.contactName}
                      helperText={formData.contactName ? '' : errors.contactName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Contact Phone Number", selectedRegion)}
                      name="contactPhone"
                      value={formData.contactPhone}
                      onChange={handleChange}
                      fullWidth
                      required
                      disabled={isChina || isShippingNotes || !isEditMode}
                      error={!!errors.contactPhone && !formData.contactPhone}
                      helperText={formData.contactPhone ? '' : errors.contactPhone}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <FormControl fullWidth required disabled={isChina || isShippingNotes || !isEditMode} error={!!errors.purpose && !formData.purpose}>
                      <InputLabel>{GetTextByRegion("Purpose", selectedRegion)}</InputLabel>
                      <Select
                        label="Purpose"
                        name="purpose"
                        value={formData.purpose}
                        onChange={handleChange}
                      >
                        <MenuItem value="Customer Service">{GetTextByRegion("Customer Service", selectedRegion)}</MenuItem>
                        <MenuItem value="Loaner">{GetTextByRegion("Loaner", selectedRegion)}</MenuItem>
                        <MenuItem value="Scienscope Stock">{GetTextByRegion("Scienscope Stock", selectedRegion)}</MenuItem>
                      </Select>
                      {errors.purpose && !formData.purpose && (
                        <FormHelperText>{errors.purpose}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box mt={1} ml={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.warranty}
                          onChange={handleChange}
                          disabled={isChina || isShippingNotes || !isEditMode}
                          name="warranty"
                        />
                      }
                      label={GetTextByRegion("Warranty", selectedRegion)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.isHighValue}
                          onChange={handleChange}
                          disabled={isChina || isShippingNotes || !isEditMode}
                          name="isHighValue"
                        />
                      }
                      label={GetTextByRegion("Includes High Value Component", selectedRegion)}
                    />
                  </Box>

                </Grid>

              </>
            )}

            {/* {tab === 1 && (
              <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth required disabled={isChina || isShippingNotes} error={!!errors.shippingMethod && !formData.shippingMethod}>
                  <InputLabel>{GetTextByRegion("Shipping Urgency", selectedRegion)}</InputLabel>
                  <Select
                    label="Shipping Urgency"
                    name="shippingMethod"
                    value={formData.shippingMethod}
                    onChange={handleChange}
                  >
                    <MenuItem value="Not Urgent">Not Urgent</MenuItem>
                    <MenuItem value="Urgent">Urgent</MenuItem>
                  </Select>
                  {errors.shippingMethod && !formData.shippingMethod && (
                    <FormHelperText>{errors.shippingMethod}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            )} */}

            <Grid item xs={12} sm={6}>
              <Box>
                <FormControl fullWidth required>
                  <InputLabel shrink>{tab === 0 ? GetTextByRegion("Number of Part Numbers", selectedRegion) : GetTextByRegion("Number of Items", selectedRegion)}</InputLabel>
                  <Select
                    label={tab === 0 ? GetTextByRegion("Number of Part Numbers", selectedRegion) : GetTextByRegion("Number of Items", selectedRegion)}
                    value={numPartNumbers}
                    onChange={handleNumPartNumbersChange}
                    disabled={(isChina && userRights < roles['MANAGER']) || isShippingNotes || !isEditMode}
                    labelId="num-part-numbers-select-label"
                  >
                    {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            {/* Part Number and Quantity Fields */}
            {Array.from({ length: numPartNumbers }).map((_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <PartNumberTextField
                      value={formData.partNumber[index] || ''}
                      onChange={(newValue) => {
                        const newPartNumbers = [...formData.partNumber];
                        newPartNumbers[index] = newValue;
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          partNumber: newPartNumbers,
                        }));
                      }}
                      required={true}
                      selectedRegion={selectedRegion}
                      // disabled={(isChina && userRights < roles['MANAGER']) || isShippingNotes || !isEditMode}
                      disabled={!isEditMode || isShippingNotes}
                      formSubmitted={formSubmitted}
                      index={index}
                      inhouse={tab === 1 ? true : false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <TextField
                      label={GetTextByRegion("Quantity", selectedRegion) + ` ${index + 1}`}
                      type="number"
                      name={`quantity-${index}`}
                      value={formData.quantity[index] || '1'}
                      onChange={(event) => {
                        const newQuantity = [...formData.quantity];
                        newQuantity[index] = event.target.value;
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          quantity: newQuantity,
                        }));
                      }}
                      fullWidth
                      required
                      disabled={(isChina && userRights < roles['MANAGER']) || isShippingNotes || !isEditMode}
                      inputProps={{ min: 1 }}
                    />
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Box>
                <TextField
                  label={GetTextByRegion("Notes", selectedRegion)}
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  disabled={isChina || isShippingNotes || !isEditMode}
                  rows={3}
                  sx={{ mb: 2 }}
                />
              </Box>
            </Grid>

            {isChina && (
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label={GetTextByRegion("Approval Notes", selectedRegion)}
                    name="notes"
                    value={formData.approvalNotes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    disabled={isChina || isShippingNotes || !isEditMode}
                    rows={2}
                    sx={{ mb: 2 }}
                  />
                </Box>
              </Grid>

            )}

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {(isChina || isShippingNotes) && (
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: 'center' }}
                >
                  <strong>{GetTextByRegion("Filled Out by China", selectedRegion)}</strong>
                </Typography>
              )}
            </div>

            <Grid item xs={12}>
              <Box>
                {isChina && isEditMode && (
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="h6">{GetTextByRegion("Tracking Numbers", selectedRegion)}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        onClick={() => handleNumTrackingNumbersChange(numTrackingNumbers + 1)}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>

            <ShippingFormFields
              numTrackingNumbers={numTrackingNumbers} // Example number
              formData={formData}
              handleShippingCompanyChange={handleShippingCompanyChange}
              handleTrackingNumberChange={handleTrackingNumberChange}
              handleTrackingNotesChange={handleTrackingNotesChange}
              isShippingNotes={isShippingNotes} // Example value
              isEditMode={isEditMode} // Example value
              selectedRegion={selectedRegion} // Example value
              isChina={isChina} // Example value
            />

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '15px' }}>
              {(isChina || isShippingNotes) && (
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: 'center' }}
                >
                  <strong>{GetTextByRegion("Filled Out by USA", selectedRegion)}</strong>
                </Typography>
              )}
            </div>

            {(isChina || isShippingNotes) && (
              <Grid item xs={12}>
                <Box>
                  <TextField
                    label={GetTextByRegion("Shipping Notes", selectedRegion)}
                    name="shippingNotes"
                    value={formData.shippingNotes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    disabled={isChina || !isEditMode}
                    rows={2}
                    sx={{ mb: 2 }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {isNew && !isChina ? (
            <>
              <Button onClick={handleCancel} color="secondary">
                {GetTextByRegion("Close", selectedRegion)}
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="contained" sx={{ mr: 5 }}>
                {GetTextByRegion("Submit", selectedRegion)}
              </Button>
            </>
          ) : isEditMode && (!isNew || isChina) ? (
            <>
              <Button onClick={() => { setIsEditMode(false) }} color="secondary">
                {GetTextByRegion("Cancel", selectedRegion)}
              </Button>
              {isChina && (
                <Button onClick={handleSave} color="primary">
                  {GetTextByRegion("Save", selectedRegion)}
                </Button>
              )}
              <Button onClick={handleSubmit} color="primary" variant="contained" sx={{ mr: 5 }}>
                {GetTextByRegion("Submit", selectedRegion)}
              </Button>
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ width: '100%' }}>
                {tabValue === 0 && userRights >= roles['MODERATOR'] && !isChina && (
                  <>
                    <Typography> In Stock? </Typography>
                    <IconRejectUnfilledButton onUpdateStatus={onUpdateStatus} />
                    <IconAcceptUnfilledButton onInventoryUpdate={onInventoryUpdate} inventoryErrors={inventoryErrors} />
                  </>
                )}
                {tabValue === 1 && !isChina && userRights >= roles['ADMIN'] && (
                  <>
                    <IconRejectButton onClickEvent={onReject} />
                    <IconAcceptButton onClickEvent={onAccept} />
                  </>
                )}
                {tabValue === 2 && !isChina && userRights >= roles['MODERATOR'] && (
                  <>
                    <Button onClick={onMarkComplete} color="primary" sx={{ mr: 5 }}>
                      {GetTextByRegion("Mark as Complete", selectedRegion)}
                    </Button>
                    <Button onClick={onSendToChina} sx={{ mr: 5, color: '#d81b60' }}>
                      {GetTextByRegion("Send to China", selectedRegion)}
                    </Button>

                  </>
                )}
              </Box>
              <Button onClick={handleCancel} color="secondary">
                {GetTextByRegion("Close", selectedRegion)}
              </Button>
              {(tabValue === 0 || tabValue === 2 || tabValue === 4 || (tabValue === 3 && userRights >= roles['MODERATOR'] && editItem.status === 'finished')) && (
                <IconButton
                  color="primary"
                  sx={{ mr: 5 }} // Adds margin-right of 2 spacing units
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                >
                  <EditIcon sx={{ fontSize: 20 }} />
                  <Typography variant="body1"> {/* Changes the text size */}
                    {GetTextByRegion("Edit", selectedRegion)}
                  </Typography>
                </IconButton>
              )}
            </>
          )}

        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error}
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </>
  );
};

export default ItemRequestForm;