const roles = {
  ADMIN: 7,
  MANAGER: 6,
  MODERATOR: 5,
  PRODUCTION: 4,
  SALES: 3,
  SALESASSISTANT: 2,
  SERVICE: 1,
};

export default roles;