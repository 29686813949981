import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import { GetTextByRegion } from '../../hooks/Translation';
import LoadingSpinner from '../LoadingSpinner'

const EmailConfirmationDialog = ({ open, onClose, onConfirm, rdEmail, selectedRegion }) => {
    const [username, setUsername] = useState('China R&D');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (rdEmail) {
            const [firstEmail, secondEmail] = rdEmail.split('&').map((e) => e.trim());
            setEmail(firstEmail || ''); // Populate first email
            setEmail2(secondEmail || ''); // Populate second email
        }
    }, [rdEmail, open]);

    const handleConfirm = async () => {
        try{
            setLoading(true);
            await onConfirm(email, email2);
        }
        catch(error){
            console.log(error);
        }
        finally{
            setLoading(false);
        }
        
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <>
        {loading && <LoadingSpinner />}
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{GetTextByRegion("Confirm Notification Email", selectedRegion)}</DialogTitle>
            <DialogContent >
                <Box display="flex" flexDirection="column" gap={2} mt={1}>
                    <TextField
                        label="Email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        value={email}
                        multiline
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box display="flex" flexDirection="column" gap={2} mt={1}>
                    <TextField
                        label="Email"
                        type="email"
                        onChange={(e) => setEmail2(e.target.value)}
                        fullWidth
                        value={email2}
                        multiline
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                {GetTextByRegion("Cancel", selectedRegion)}
                </Button>
                <Button onClick={handleConfirm} color="primary" variant="contained">
                {GetTextByRegion("Confirm", selectedRegion)}
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

export default EmailConfirmationDialog;
