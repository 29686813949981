import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import axios from 'axios';
import LoadingSpinner from '../LoadingSpinner';
import DeleteIcon from '@mui/icons-material/Delete';
import ModelDropdown from '../ModelDropdown';
import ErrorDialog from '../ErrorDialog';
import { useTheme } from '@mui/material/styles';
import { GetTextByRegion } from '../../hooks/Translation';
import { useUser } from '../../hooks/UserContext';
import { format } from 'date-fns';

const backendString = process.env.REACT_APP_BACKEND_STRING;

const NonconformingReportForm = ({ open, onClose, report, onSave, onSubmit }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false); // New loading state
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [error, setError] = useState(null); // State for error messages
  const [errors, setErrors] = useState({}); // State for error messages
  const { selectedRegion, user: { username } } = useUser();
  const [formData, setFormData] = useState({
    dateReported: format(new Date(), 'yyyy-MM-dd'),
    model: '',
    reportNumber: `${username}_`,
    serialNumber: '',
    customer: '',
    reportBy: username,
    issueType: '',
    problem: '',
    description: '',
    suggestedSolution: '',
    images: [],
    formName: 'Nonconforming',
    reportedRegion: selectedRegion,
  });

  useEffect(() => {
    if (report) {
      let formattedDateTime = '';
      if (report.dateReported) {
        const dateObject = new Date(report.dateReported);
        if (!isNaN(dateObject.getTime())) {
          // Format the Date object to 'YYYY-MM-DDTHH:MM' format
          formattedDateTime = dateObject.toISOString().slice(0, 16);
        }
      }
      setFormData({
        dateReported: formattedDateTime,
        reportBy: username,
        ...report,
        images: report.images || [],
        formName: 'Nonconforming',
      });
    }
  }, [report, username]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Update the specific field that triggered the change
      const updatedData = { ...prevData, [name]: value };

      // Always update reportNumber based on reportData.name and reportData.model
      updatedData.reportNumber = `${updatedData.reportBy || formData.reportBy}_${updatedData.model || formData.model}_${updatedData.dateReported || formData.dateReported}`;

      return updatedData;
    });
  };

  const handleFileChange = (e) => {
    const existingImages = formData.images;
    const newFiles = Array.from(e.target.files);
    const updatedImages = existingImages.concat(newFiles);
    setFormData({
      ...formData,
      images: updatedImages
    });
  };

  const handleDeleteImage = (index) => {
    const imageToDelete = formData.images[index];

    if (typeof imageToDelete === 'string') {
      setImagesToDelete((prevImagesToDelete) => [...prevImagesToDelete, imageToDelete]);
    }

    setFormData((prevFormData) => {
      const updatedImages = prevFormData.images.filter((_, i) => i !== index);

      if (report) {
        report.images = updatedImages; // Update form.images if needed
      }

      return {
        ...prevFormData,
        images: updatedImages,
      };
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.formName) newErrors.formName = GetTextByRegion('Form Name is required', selectedRegion);
    if (!formData.dateReported) newErrors.dateReported = GetTextByRegion('Date Reported is required', selectedRegion);
    if (!formData.model) newErrors.model = GetTextByRegion('Model is required', selectedRegion);
    if (!formData.reportNumber) newErrors.reportNumber = GetTextByRegion('Report Number is required', selectedRegion);
    if (!formData.serialNumber) newErrors.serialNumber = GetTextByRegion('Serial Number is required', selectedRegion);
    if (!formData.customer) newErrors.customer = GetTextByRegion('Customer is required', selectedRegion);
    if (!formData.reportBy) newErrors.reportBy = GetTextByRegion('Report By is required', selectedRegion);
    if (!formData.issueType) newErrors.issueType = GetTextByRegion('Issue Type is required', selectedRegion);
    if (!formData.problem) newErrors.problem = GetTextByRegion('Problem is required', selectedRegion);
    if (!formData.description) newErrors.description = GetTextByRegion('Description is required', selectedRegion);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    if (!validate()) {
      return;
    }
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('status', 'Pending');
      formDataToSend.append('formName', formData.formName);
      formDataToSend.append('dateReported', formData.dateReported);
      formDataToSend.append('model', formData.model);
      formDataToSend.append('reportNumber', formData.reportNumber);
      formDataToSend.append('serialNumber', formData.serialNumber);
      formDataToSend.append('customer', formData.customer);
      formDataToSend.append('reportBy', formData.reportBy);
      formDataToSend.append('issueType', formData.issueType);
      formDataToSend.append('problem', formData.problem);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('suggestedSolution', formData.suggestedSolution);
      formDataToSend.append('reportedRegion', formData.reportedRegion);
      formData.images.forEach((image, index) => {
        if (typeof image === 'string') {
          formDataToSend.append('existingImageIds', image);
        } else {
          formDataToSend.append('images', image, `image${index}`);
        }
      });

      // Determine the base URL based on the selected region
      let connectionString = `${backendString}/nonconforming`;

      const url = report ? `${connectionString}/${report._id}?reportedRegion=${formData.reportedRegion}` : `${connectionString}?selectedRegion=${selectedRegion}`;
      const method = report ? 'put' : 'post';

      const response = await axios[method](url, formDataToSend, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        }
      });

      const response1 = await axios.get(
        `${backendString}/users/email/CHINA_QA_EMAIL`
      );

      const response2 = await axios.get(
        `${backendString}/users/email/CHINA_PRODUCTION_EMAIL`
      );

      const response3 = await axios.get(
        `${backendString}/users/email/CEO_EMAIL`
      );


      //send email saying submitted
      if (response1.data.email || response2.data.email) {

        const requestData = {
          chinaProductionEmail: response1.data.email,
          chinaQAEmail: response2.data.email,
          ceoEmail: response3.data.email,
          reportNumber: formData.reportNumber
      }

        // Send the email
        await axios.post(`${connectionString}/send-email/pending?selectedRegion=${selectedRegion}`,  requestData, {
          headers: {
            'Authorization': sessionStorage.getItem('token'),
          }
        });

      }

      onClose();
      onSubmit(response.data, true);
      onSave();
      resetFormData();
    } catch (error) {
      console.error('Error uploading data:', error);
      setError(error.response?.data?.error); // Set error state
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!validate()) {
      return;
    }
    setLoading(true); // Start the loading state
    try {
      // Determine the base URL based on the selected region
      let connectionString = `${backendString}/nonconforming`;

      // Delete images marked for deletion
      await Promise.all(
        imagesToDelete.map(async (image) => {
          try {
            await axios.delete(`${connectionString}/images/${image}?reportedRegion=${formData.reportedRegion}`, {
              headers: {
                'Authorization': sessionStorage.getItem('token'),
              },
            });
          } catch (error) {
            console.error(`Error deleting image with ID ${image}:`, error);
            setError(error.response?.data?.error); // Set error state
            // Continue with the next image deletion if this one fails
          }
        })
      );

      // Prepare form data
      const formDataToSend = new FormData();
      formDataToSend.append('formName', formData.formName);
      formDataToSend.append('dateReported', formData.dateReported);
      formDataToSend.append('model', formData.model);
      formDataToSend.append('reportNumber', formData.reportNumber);
      formDataToSend.append('serialNumber', formData.serialNumber);
      formDataToSend.append('customer', formData.customer);
      formDataToSend.append('reportBy', formData.reportBy);
      formDataToSend.append('issueType', formData.issueType);
      formDataToSend.append('problem', formData.problem);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('suggestedSolution', formData.suggestedSolution);
      formDataToSend.append('reportedRegion', formData.reportedRegion);

      formData.images.forEach((image, index) => {
        if (typeof image === 'string') {
          formDataToSend.append('existingImageIds', image);
        } else {
          formDataToSend.append('images', image, `image${index}`);
        }
      });

      // Determine the URL and method for the API call
      const url = report ? `${connectionString}/${report._id}?reportedRegion=${formData.reportedRegion}` : `${connectionString}?selectedRegion=${selectedRegion}`;
      const method = report ? 'put' : 'post';

      // Make the API request to save the form data
      await axios[method](url, formDataToSend, {
        headers: {
          'Authorization': sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        }
      });

      // Handle post-save actions
      onSave();
      onClose();
      resetFormData();
      setImagesToDelete([]); // Reset the images to delete list
    } catch (error) {
      console.error('Error saving form:', error);
      setError(error.response?.data?.error); // Set error state
    } finally {
      setLoading(false); // Stop the loading state
    }
  };


  const handleClose = () => {
    setImagesToDelete([]); // Reset the images to delete list
    resetFormData();
    setErrors({});
    onClose();
  }


  const resetFormData = () => {
    setFormData({
      dateReported: '',
      model: '',
      reportNumber: `${username}-${formData.model}-${formData.dateReported}`,
      serialNumber: '',
      customer: '',
      reportBy: username,
      issueType: '',
      problem: '',
      description: '',
      images: [],
      formName: 'Nonconforming',
      reportedRegion: selectedRegion,
    });
  };

  const handleCloseErrorDialog = () => {
    setError(null); // Reset error state
  };

  return (
    <div>
      {loading && <LoadingSpinner />}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{report ? GetTextByRegion('Edit Nonconforming Report', selectedRegion) : GetTextByRegion('Add Nonconforming Report', selectedRegion)}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={GetTextByRegion("Date Reported", selectedRegion)}
                type="date"
                name="dateReported"
                value={formData.dateReported}
                onChange={handleChange}
                required
                InputLabelProps={{ shrink: true }}
                error={!!errors.dateReported && !formData.dateReported}
                helperText={formData.dateReported ? '' : errors.dateReported}
                inputProps={{
                  style: { color: !formData.dateReported && errors.dateReported ? theme.palette.error.main : 'inherit' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ModelDropdown
                value={formData.model}
                name="model"
                onChange={handleChange}
                required
                errors={errors.model}
                selectedRegion={selectedRegion}
                helperText={formData.model ? '' : errors.model}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={GetTextByRegion("Serial Number", selectedRegion)}
                name="serialNumber"
                value={formData.serialNumber}
                onChange={handleChange}
                required
                error={!!errors.serialNumber && !formData.serialNumber}
                helperText={formData.serialNumber ? '' : errors.serialNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={GetTextByRegion("Customer", selectedRegion)}
                name="customer"
                value={formData.customer}
                onChange={handleChange}
                required
                error={!!errors.customer && !formData.customer}
                helperText={formData.customer ? '' : errors.customer}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                required
                error={!!errors.issueType && !formData.issueType}
                variant="outlined"
              >
                <InputLabel>{GetTextByRegion("Issue Type", selectedRegion)}</InputLabel>
                <Select
                  name="issueType"
                  value={formData.issueType}
                  onChange={handleChange}
                  label={GetTextByRegion("Issue Type", selectedRegion)}
                // Add additional styling if needed
                >
                  <MenuItem value="Mechanical">{GetTextByRegion("Mechanical", selectedRegion)}</MenuItem>
                  <MenuItem value="X-ray-parts">{GetTextByRegion("X-ray Parts", selectedRegion)}</MenuItem>
                  <MenuItem value="Electrical">{GetTextByRegion("Electrical", selectedRegion)}</MenuItem>
                  <MenuItem value="Electrical">{GetTextByRegion("Software", selectedRegion)}</MenuItem>
                </Select>
                <FormHelperText>{formData.issueType ? '' : errors.issueType}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={GetTextByRegion("Report Number", selectedRegion)}
                name="reportNumber"
                value={formData.reportNumber}
                // onChange={handleChange}
                required
                disabled
                error={!!errors.reportNumber && !formData.reportNumber}
                helperText={formData.reportNumber ? '' : errors.reportNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={GetTextByRegion("Reported By", selectedRegion)}
                name="reportBy"
                value={formData.reportBy}
                required
                disabled
                error={!!errors.reportBy && !formData.reportBy}
                helperText={formData.reportBy ? '' : errors.reportBy}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={GetTextByRegion("Problem", selectedRegion)}
                name="problem"
                value={formData.problem}
                onChange={handleChange}
                required
                error={!!errors.problem && !formData.problem}
                helperText={formData.problem ? '' : errors.problem}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={GetTextByRegion("Description", selectedRegion)}
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                error={!!errors.description && !formData.description}
                helperText={formData.description ? '' : errors.description}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={GetTextByRegion("Suggested Solution", selectedRegion)}
                name="suggestedSolution"
                value={formData.suggestedSolution}
                onChange={handleChange}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1"> {GetTextByRegion("Upload Images", selectedRegion)}:</Typography>
              <Button
                variant="contained"
                component="label"
                color="primary"
              >
                {GetTextByRegion("Upload File", selectedRegion)}
                <input
                  type="file"
                  hidden
                  name="images"
                  multiple
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              {formData.images.length > 0 && (
                <div>
                  <Typography variant="body2">Uploaded Images:</Typography>
                  <Grid container spacing={2}>
                    {formData.images.map((image, index) => (
                      <Grid item key={index}>
                        <div style={{ position: 'relative' }}>
                          {typeof image === 'string' ? (
                            <img
                              src={backendString + '/nonconforming/images/' + image + `?reportedRegion=${formData.reportedRegion}`}
                              // alt={`Uploaded ${index}`}
                              alt={backendString + '/nonconforming/images/' + image + `?reportedRegion=${formData.reportedRegion}`}
                              width="100"
                              height="100"
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Uploaded ${index}`}
                              width="100"
                              height="100"
                            />
                          )}
                          <IconButton
                            onClick={() => handleDeleteImage(index)}
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              background: 'rgba(255, 255, 255, 0.7)'
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              {GetTextByRegion("Cancel", selectedRegion)}
            </Button>
            <Button color="primary" onClick={handleSave}>
              {GetTextByRegion("Save", selectedRegion)}
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {GetTextByRegion("Submit", selectedRegion)}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Error Dialog */}
      <ErrorDialog
        open={!!error} // Ensure this is a boolean
        onClose={handleCloseErrorDialog}
        errorMessage={error}
      />
    </div>
  );
};

export default NonconformingReportForm;
