import React, { useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/Machine.css';

const CustomDataGrid = ({ rows, columns, width, visibilityColumnConfig, setColumnVisibility, tableName }) => {
  const minWidth = width || '1000px';
  const backendString = process.env.REACT_APP_BACKEND_STRING || "http://localhost:5000";
  

  const retryRequest = async (axiosRequest, retries = 3, delay = 1000) => {
    try {
      return await axiosRequest();
    } catch (error) {
      if (retries > 0 && error.response?.status === 429) {
        console.warn("Rate limit hit. Retrying...");
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retryRequest(axiosRequest, retries - 1, delay * 2); // Double delay each retry
      }
      throw error;
    }
  };

  const handleColumnChange = async (columnName) => {
    if (["inventory", "machine", "rack", "shipped", "onsite", "complete","inproduction","stock","productionShipped","demo","aftersale"].includes(tableName)) {
      setColumnVisibility(columnName);     
      const columnCount = Object.keys(columns).length;
      const hiddenColumnCount = Object.values(columnName).filter((value) => value === false).length;
      const visibilityColumnString = JSON.stringify(columnName);
      if(columnCount==hiddenColumnCount){
        console.log("You can't hide all columns!");
        return;
      }
      try {
        await retryRequest(() =>
          axios.post(
            `${backendString}/savetableColumns`,
            { tableName, columnString: visibilityColumnString },
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
              },
            }
          )
        );
        //console.log("Column visibility saved successfully!");
      } catch (error) {
       // console.error("Error saving column visibility:", error.response || error.message);
      }
    }
  };


  const commonProps = {
    rows,
    columns,
    getRowId: (row) => row._id,
    rowHeight: 65,
    style: { minWidth },
    getRowClassName: (params) => {
      const urgency = params.row.urgent;

      if (params.row.status === 'On Floor: Scienscope Demo') return 'demo';
      if (params.row.status === 'Broken') return 'broken';
      if (urgency > 0) return 'urgent';
    },
  };

  return (
    <div className="no-hover" style={{ height: '100%', width: '100%', overflowX: 'auto' }}>
      <DataGrid
        {...commonProps}
        columnVisibilityModel={["inventory", "machine", "rack", "shipped", "onsite", "complete","inproduction","stock","productionShipped","demo","aftersale"].includes(tableName) ? visibilityColumnConfig || {} : undefined}
        onColumnVisibilityModelChange={["inventory", "machine", "rack", "shipped", "onsite", "complete","inproduction","stock","productionShipped","demo","aftersale"].includes(tableName) ? handleColumnChange : undefined}
      />
    </div>
  );
};

export default CustomDataGrid;
