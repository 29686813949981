import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useUser } from '../../hooks/UserContext';
import useWindowSize from '../../hooks/useWindowSize';
import { format } from 'date-fns';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailConfirmationDialog from './EmailConfirmationDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import { GetTextByRegion } from '../../hooks/Translation';


const backendString = process.env.REACT_APP_BACKEND_STRING;

const ChinaProductionHistoryLogDialog = ({
    open,
    onClose,
    machine,
    setMachine,
}) => {
    const { selectedRegion, user: { username } } = useUser();
    const [history, setHistory] = useState(machine?.history || []);
    const [isNotificationConfirmationOpen, setIsNotificationConfirmationOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [email, setEmail] = useState('');
    const [editingEntry, setEditingEntry] = useState(null);
    const [editingEntryIndex, setEditingEntryIndex] = useState(null);
    const [deletingEntryIndex, setDeletingEntryIndex] = useState(null);
    const [newEntry, setNewEntry] = useState({
        date: format(new Date(), 'yyyy-MM-dd'),
        notes: '',
        tech: username,
    });
    const [isNewEntryDialogOpen, setIsNewEntryDialogOpen] = useState(false);
    const { windowSize, smallWidth } = useWindowSize();

    useEffect(() => {
        if (machine?.history) {
            setHistory(machine.history);
        }
    }, [machine]);

    const handleOpenEmailConfirmation = async () => {
        try {
            const response = await axios.get(backendString + `/users/email/China R&D`);
            setEmail(response.data.email);
        } catch (error) {
            console.error(error);
        }
        setIsNotificationConfirmationOpen(true);
    };

    const handleCloseEmailConfirmation = () => {
        setIsNotificationConfirmationOpen(false);
    };

    const handleEmailSubmit = async (email, email2) => {
        if (email) {
            try {
                await axios.post(backendString + `/chinaproduction/send-email/rd`, {
                    email: email,
                    serial: machine.machineNo,
                });
            } catch (error) {
                console.error(error);
            }
        }

        if (email2) {
            try {
                await axios.post(backendString + `/chinaproduction/send-email/rd`, {
                    email: email2,
                    serial: machine.machineNo,
                });
            } catch (error) {
                console.error(error);
            }
        }

        // After sending the emails, add a new history entry
        const newHistoryEntry = {
            date: format(new Date(), 'yyyy-MM-dd'),
            notes: `已通知：${email} 和 ${email2}`,
            tech: username,
        };

        const updatedHistory = [...history, newHistoryEntry];

        // Update the history on the backend
        try {
            await axios.put(`${backendString}/chinaproduction/${machine._id}`, {
                history: updatedHistory,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                },
            });
            // Update the state with the new history
            setHistory(updatedHistory);
        } catch (error) {
            console.error('Failed to update history:', error);
        }

        handleCloseEmailConfirmation();
    };

    const handleNewEntrySubmit = async () => {
        const updatedHistory = [...history, newEntry];
        try {
            await axios.put(`${backendString}/chinaproduction/${machine._id}`, {
                history: updatedHistory,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                },
            });
            setHistory(updatedHistory);
            setNewEntry({ date: format(new Date(), 'yyyy-MM-dd'), notes: '', tech: username });
            setIsNewEntryDialogOpen(false);
        } catch (error) {
            console.error('Failed to update history:', error);
        }
    };

    const handleEditEntry = (index) => {
        const entryToEdit = history[index];
        setEditingEntry(entryToEdit);  // Store the entry being edited
        setNewEntry(entryToEdit);      // Set it to `newEntry` for modification
        setEditingEntryIndex(index);  // Set the index of the entry being edited
        setIsNewEntryDialogOpen(true); // Open the dialog for editing
    };


    const handleSaveEdit = async () => {
        if (editingEntryIndex === null) return;  // Ensure there's an index to update

        // Find the entry to update using index and modify it
        const updatedHistory = [...history];  // Make a shallow copy
        updatedHistory[editingEntryIndex] = newEntry;  // Update the entry by index

        try {
            await axios.put(`${backendString}/chinaproduction/${machine._id}`, {
                history: updatedHistory,
            }, {
                headers: {
                    'Authorization': sessionStorage.getItem('token')
                },
            });
            setHistory(updatedHistory);  // Update the state with new history
            setEditingEntry(null);        // Clear the editing entry
            setEditingEntryIndex(null);   // Reset the editing entry index
            setIsNewEntryDialogOpen(false);  // Close the dialog
        } catch (error) {
            console.error('Failed to save edited entry:', error);
        }
    };



    const handleDeleteEntry = () => {
        const updatedHistory = history.filter((entry, i) => i !== deletingEntryIndex);  // Remove the entry by index
        axios.put(`${backendString}/chinaproduction/${machine._id}`, { history: updatedHistory }, {
            headers: {
                'Authorization': sessionStorage.getItem('token')
            },
        })
            .then(() => {
                setHistory(updatedHistory);  // Update the state with new history
            })
            .catch((error) => {
                console.error('Failed to delete entry:', error);
            });
        setOpenDeleteDialog(false)
    };

    const handleDelete = (params) => {
        setDeletingEntryIndex(params)
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeletingEntryIndex(null);
    };



    const columns = [
        { field: 'date', headerName: GetTextByRegion("Date", selectedRegion), flex: 1 },
        { field: 'notes', headerName: GetTextByRegion("Notes", selectedRegion), flex: 2 },
        { field: 'tech', headerName: GetTextByRegion("Technician", selectedRegion), flex: 1 },
        {
            field: 'actions',
            headerName: GetTextByRegion("Actions", selectedRegion),
            renderCell: (params) => {
                return (
                    <>
                        <IconButton color="primary" onClick={() => handleEditEntry(params.row.id)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                );
            },
            sortable: false,
            filterable: false,
            flex: 1,
        },
    ];

    return (
        <>
            <Dialog open={open} onClose={onClose} fullScreen={smallWidth}>
                <DialogTitle>{GetTextByRegion("History Log", selectedRegion)}</DialogTitle>
                <DialogContent>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsNewEntryDialogOpen(true)}
                        sx={{ marginRight: 2 }}
                    >
                        {GetTextByRegion("Add New Entry", selectedRegion)}
                    </Button>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <IconButton color="secondary" size="large" onClick={handleOpenEmailConfirmation}>
                        <NotificationsActiveIcon />
                    </IconButton>
                    <Box sx={{ height: windowSize.height * 0.80, width: '100%' }}>
                        <DataGrid
                            rows={history.map((row, index) => ({ ...row, id: index }))}
                            columns={columns}
                            pageSize={5}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary">{GetTextByRegion("Cancel", selectedRegion)}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isNewEntryDialogOpen} onClose={() => setIsNewEntryDialogOpen(false)}>
                <DialogTitle>{editingEntry ? GetTextByRegion("Edit Entry", selectedRegion) : GetTextByRegion("Add New Entry", selectedRegion)}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={GetTextByRegion("Date", selectedRegion)}
                        type="date"
                        fullWidth
                        value={newEntry.date}
                        onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                    />
                    <TextField
                        label={GetTextByRegion("Notes", selectedRegion)}
                        fullWidth
                        value={newEntry.notes}
                        onChange={(e) => setNewEntry({ ...newEntry, notes: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label={GetTextByRegion("Technician", selectedRegion)}
                        fullWidth
                        value={newEntry.tech}
                        onChange={(e) => setNewEntry({ ...newEntry, tech: e.target.value })}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsNewEntryDialogOpen(false);
                            setNewEntry({ date: format(new Date(), 'yyyy-MM-dd'), notes: '', tech: username }); // Reset newEntry to blank
                        }}
                        color='secondary'
                    >
                        {GetTextByRegion("Cancel", selectedRegion)}
                    </Button>
                    <Button
                        onClick={editingEntry ? handleSaveEdit : handleNewEntrySubmit}
                        color="primary"
                        variant="contained"
                    >
                        {editingEntry ? GetTextByRegion("Save", selectedRegion) : GetTextByRegion("Submit", selectedRegion)}
                    </Button>
                </DialogActions>
            </Dialog>

            <EmailConfirmationDialog
                open={isNotificationConfirmationOpen}
                onClose={handleCloseEmailConfirmation}
                onConfirm={handleEmailSubmit}
                rdEmail={email}
                selectedRegion={selectedRegion}
            />

            <DeleteConfirmationDialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}    // Close the dialog when cancelled
                onConfirm={handleDeleteEntry}        // Delete the selected entry on confirmation
                selectedRegion={selectedRegion}
            />

        </>
    );
};

export default ChinaProductionHistoryLogDialog;
